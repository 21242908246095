import React, { useState } from 'react';
import { EnvelopeIcon, PhoneIcon, MapPinIcon, BoltIcon } from '@heroicons/react/24/outline';
import { useTheme } from '../contexts/ThemeContext';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

const ContactPage = () => {
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const defaultCenter = {
    lat: -30.5595,
    lng: 22.9375
  };

  // Key service areas in South Africa
  const serviceLocations = [
    { lat: -33.9249, lng: 18.4241, title: "Cape Town Office" },
    { lat: -26.2041, lng: 28.0473, title: "Johannesburg Office" },
    { lat: -29.8587, lng: 31.0218, title: "Durban Office" },
    { lat: -33.7139, lng: 25.5207, title: "Port Elizabeth Office" },
    { lat: -25.7479, lng: 28.2293, title: "Pretoria Office" }
  ];

  // Additional SEO metadata
  const additionalMetaTags = [
    { name: "robots", content: "index, follow" },
    { name: "author", content: "Solar Insight South Africa" },
    { name: "geo.region", content: "ZA" },
    { name: "geo.position", content: "-30.5595;22.9375" },
    { name: "ICBM", content: "-30.5595, 22.9375" },
    { name: "twitter:card", content: "summary_large_image" },
    { name: "twitter:title", content: "Contact Solar Insight | Leading Solar Power Solutions in South Africa" },
    { name: "twitter:description", content: "Get professional solar power solutions across South Africa. 24/7 support, free quotes, and expert consultation for your solar energy needs." }
  ];

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
    location: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await fetch('https://YOUR_REGION-YOUR_PROJECT_ID.cloudfunctions.net/sendEmail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
      });

      if (!response.ok) {
        throw new Error('Failed to send message');
      }

      setFormData({
        name: '',
        email: '',
        phone: '',
        message: '',
        location: '',
      });
      
      navigate('/thank-you');
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('There was an error submitting your message. Please try again or contact us directly at hello@solarinsight.co.za');
    } finally {
      setIsSubmitting(false);
    }
  };

  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "name": "Solar Insight - Solar Power Solutions South Africa",
    "image": "https://solarinsight.co.za/logo.png",
    "description": "Leading solar power installation and consultation service in South Africa. Contact us for professional solar solutions and load shedding protection.",
    "address": {
      "@type": "PostalAddress",
      "addressRegion": "Western Cape",
      "addressCountry": "ZA"
    },
    "url": "https://solarinsight.co.za",
    "telephone": "+27764489618",
    "email": ["hello@solarinsight.co.za", "freequote@solarinsight.co.za"],
    "areaServed": {
      "@type": "Country",
      "name": "South Africa"
    }
  };

  const inputClassName = `block w-full rounded-md p-2.5 border ${
    isDarkMode 
      ? 'bg-blue-700 border-blue-600 text-blue-100 focus:ring-yellow-400 focus:border-yellow-400 placeholder-blue-300' 
      : 'bg-amber-50 border-amber-300 text-amber-900 focus:ring-amber-500 focus:border-amber-500 placeholder-amber-400'
  } shadow-sm`;

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-blue-900 text-blue-100' : 'bg-amber-50 text-amber-900'}`}>
      <Helmet>
        <title>Contact Solar Insight | Leading Solar Power Solutions in South Africa</title>
        <meta name="description" content="Professional solar power solutions across South Africa. Available 24/7 for installations, maintenance, and emergency support. Get a free quote today at +27764489618." />
        <meta name="keywords" content="solar power South Africa, solar installation, solar energy solutions, solar panels Cape Town, solar installation Johannesburg, solar power Durban, renewable energy SA, load shedding solutions" />
        {additionalMetaTags.map((tag, index) => (
          <meta key={index} name={tag.name} content={tag.content} />
        ))}
        <meta property="og:title" content="Contact Solar Insight | Leading Solar Power Solutions in South Africa" />
        <meta property="og:description" content="Professional solar power solutions across South Africa. Get expert consultation and free quotes for your solar energy needs." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://solarinsight.co.za/contact" />
        <meta property="og:image" content="https://solarinsight.co.za/images/contact-og.jpg" />
        <link rel="canonical" href="https://solarinsight.co.za/contact" />
        <script type="application/ld+json">
          {JSON.stringify(jsonLd)}
        </script>
      </Helmet>

      <main className="container mx-auto px-4 py-12 max-w-7xl">
        <div className="max-w-6xl mx-auto">
          <h1 className={`text-4xl font-bold mb-4 text-center ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>
            Contact Us
          </h1>
          <p className="text-xl mb-12 text-center">South Africa's Leading Solar Power Experts</p>

          {/* Contact Info and Form Grid */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-12">
            {/* Contact Details */}
            <div className={`rounded-lg ${isDarkMode ? 'bg-blue-800' : 'bg-white'} shadow-lg p-8`}>
              <h2 className={`text-3xl font-bold mb-8 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>
                Get In Touch
              </h2>
              <div className="space-y-6">
                <div className="flex items-center group transition-all duration-200 hover:transform hover:translate-x-2">
                  <PhoneIcon className={`h-8 w-8 ${isDarkMode ? 'text-yellow-400' : 'text-amber-500'} group-hover:scale-110 transition-transform duration-200`} />
                  <div className="ml-4">
                    <p className="font-semibold text-lg">Call Us</p>
                    <a href="tel:+27764489618" className="text-2xl hover:underline">+27 76 448 9618</a>
                    <p className="text-sm mt-1">Available 24/7 for client support</p>
                  </div>
                </div>
                <div className="flex items-center group transition-all duration-200 hover:transform hover:translate-x-2">
                  <EnvelopeIcon className={`h-8 w-8 ${isDarkMode ? 'text-yellow-400' : 'text-amber-500'} group-hover:scale-110 transition-transform duration-200`} />
                  <div className="ml-4">
                    <p className="font-semibold text-lg">Email Us</p>
                    <a href="mailto:freequote@solarinsight.co.za" className="block hover:underline">freequote@solarinsight.co.za</a>
                    <a href="mailto:hello@solarinsight.co.za" className="block hover:underline">hello@solarinsight.co.za</a>
                  </div>
                </div>
                <div className="flex items-center group transition-all duration-200 hover:transform hover:translate-x-2">
                  <MapPinIcon className={`h-8 w-8 ${isDarkMode ? 'text-yellow-400' : 'text-amber-500'} group-hover:scale-110 transition-transform duration-200`} />
                  <div className="ml-4">
                    <p className="font-semibold text-lg">Location</p>
                    <p>Western Cape, South Africa</p>
                    <p className="text-sm mt-1">Serving all major areas in South Africa</p>
                  </div>
                </div>
                <div className="flex items-center group transition-all duration-200 hover:transform hover:translate-x-2">
                  <BoltIcon className={`h-8 w-8 ${isDarkMode ? 'text-yellow-400' : 'text-amber-500'} group-hover:scale-110 transition-transform duration-200`} />
                  <div className="ml-4">
                    <p className="font-semibold text-lg">Emergency Support</p>
                    <p>24/7 Technical Assistance</p>
                    <p className="text-sm mt-1">Quick response for urgent issues</p>
                  </div>
                </div>
              </div>
            </div>

            {/* Contact Form */}
            <div className={`rounded-lg ${isDarkMode ? 'bg-blue-800' : 'bg-white'} shadow-lg p-8`}>
              <h2 className={`text-2xl font-bold mb-6 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>
                Send Us a Message
              </h2>
              <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                  <label htmlFor="name" className="block text-sm font-medium mb-1">Name *</label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    required
                    value={formData.name}
                    onChange={handleChange}
                    className={inputClassName}
                  />
                </div>
                <div>
                  <label htmlFor="email" className="block text-sm font-medium mb-1">Email *</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    required
                    value={formData.email}
                    onChange={handleChange}
                    className={inputClassName}
                  />
                </div>
                <div>
                  <label htmlFor="phone" className="block text-sm font-medium mb-1">Phone *</label>
                  <input
                    type="tel"
                    name="phone"
                    id="phone"
                    required
                    value={formData.phone}
                    onChange={handleChange}
                    placeholder="+27"
                    className={inputClassName}
                  />
                </div>
                <div>
                  <label htmlFor="message" className="block text-sm font-medium mb-1">Message *</label>
                  <textarea
                    id="message"
                    name="message"
                    rows={4}
                    required
                    value={formData.message}
                    onChange={handleChange}
                    placeholder="How can we help you?"
                    className={inputClassName}
                  />
                </div>
                <div>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className={`w-full px-6 py-3 rounded-md text-lg font-semibold ${
                      isDarkMode 
                        ? 'bg-yellow-400 text-blue-900 hover:bg-yellow-300 disabled:bg-yellow-200' 
                        : 'bg-amber-500 text-white hover:bg-amber-600 disabled:bg-amber-300'
                    } transition-colors duration-200`}
                  >
                    {isSubmitting ? 'Sending...' : 'Send Message'}
                  </button>
                </div>
                <p className="text-sm">* Required fields</p>
                <p className="text-xs">Your information is secure and will never be shared.</p>
              </form>
            </div>
          </div>

          {/* Service Areas and Map Section */}
          <div className="w-full mt-12">
            <div className="text-center mb-12">
              <h2 className={`text-3xl font-bold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>
                Need Solar in South Africa?
              </h2>
              <p className="text-xl mb-8">We Serve All Major Areas Across South Africa</p>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 max-w-4xl mx-auto">
                <div className={`p-4 rounded-lg ${isDarkMode ? 'bg-blue-800' : 'bg-white'} shadow-lg`}>
                  <h3 className="font-bold text-lg mb-2">Cape Town Region</h3>
                  <p>Western Cape's Premier Solar Solutions</p>
                </div>
                <div className={`p-4 rounded-lg ${isDarkMode ? 'bg-blue-800' : 'bg-white'} shadow-lg`}>
                  <h3 className="font-bold text-lg mb-2">Johannesburg Area</h3>
                  <p>Gauteng's Leading Solar Provider</p>
                </div>
                <div className={`p-4 rounded-lg ${isDarkMode ? 'bg-blue-800' : 'bg-white'} shadow-lg`}>
                  <h3 className="font-bold text-lg mb-2">Durban & KZN</h3>
                  <p>KwaZulu-Natal's Solar Experts</p>
                </div>
                <div className={`p-4 rounded-lg ${isDarkMode ? 'bg-blue-800' : 'bg-white'} shadow-lg`}>
                  <h3 className="font-bold text-lg mb-2">Pretoria Region</h3>
                  <p>Complete Solar Installation Services</p>
                </div>
                <div className={`p-4 rounded-lg ${isDarkMode ? 'bg-blue-800' : 'bg-white'} shadow-lg`}>
                  <h3 className="font-bold text-lg mb-2">Port Elizabeth</h3>
                  <p>Eastern Cape Solar Solutions</p>
                </div>
                <div className={`p-4 rounded-lg ${isDarkMode ? 'bg-blue-800' : 'bg-white'} shadow-lg`}>
                  <h3 className="font-bold text-lg mb-2">Nationwide Support</h3>
                  <p>24/7 Service Across South Africa</p>
                </div>
              </div>
            </div>

            {/* Map Section */}
            <div className={`rounded-lg ${isDarkMode ? 'bg-blue-800' : 'bg-white'} shadow-lg p-1`}>
              <iframe 
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3654208.065242514!2d22.937537700000005!3d-30.559482!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1c34a689d9ee1251%3A0xe85d630c1fa4e8a0!2sSouth%20Africa!5e0!3m2!1sen!2s!4v1709925800791!5m2!1sen!2s"
                width="100%"
                height="450"
                style={{ border: 0, display: 'block' }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ContactPage;