import React, { useState, useEffect } from "react";
import { useTheme } from "../../contexts/ThemeContext";
import { Helmet } from "react-helmet";
import { 
  Calculator,
  TrendingUp,
  DollarSign,
  Info,
  ArrowRight,
  RefreshCw
} from "lucide-react";
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from "recharts";

const SolarFinancingCalculator = () => {
  const { isDarkMode } = useTheme();

  // State for user inputs
  const [inputs, setInputs] = useState({
    loanAmount: 50000,       // Cost of the solar system (ZAR)
    interestRate: 8,         // Annual interest rate (%)
    paybackTerm: 5,          // Loan term in years
    monthlySavings: 1500,    // Estimated monthly savings from solar (ZAR)
  });

  // State for calculation results
  const [results, setResults] = useState(null);
  // Chart data for monthly payment vs. monthly savings
  const [chartData, setChartData] = useState([]);

  // Input ranges
  const inputRanges = {
    loanAmount: { min: 10000, max: 500000, step: 5000 },
    interestRate: { min: 0, max: 20, step: 0.5 },
    paybackTerm: { min: 1, max: 20, step: 1 },
    monthlySavings: { min: 0, max: 10000, step: 100 }
  };

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((prev) => ({
      ...prev,
      [name]: parseFloat(value),
    }));
  };

  // Calculate results
  const calculateResults = () => {
    const { loanAmount, interestRate, paybackTerm, monthlySavings } = inputs;

    // Convert annual interest rate to monthly and term to months
    const monthlyInterestRate = interestRate / 100 / 12;
    const loanTermMonths = paybackTerm * 12;

    // Calculate monthly loan payment using the standard loan formula
    const monthlyPayment = monthlyInterestRate > 0
      ? (loanAmount * monthlyInterestRate) /
        (1 - Math.pow(1 + monthlyInterestRate, -loanTermMonths))
      : loanAmount / loanTermMonths;

    // Calculate total cost of loan
    const totalLoanCost = monthlyPayment * loanTermMonths;
    const totalSavings = monthlySavings * loanTermMonths;
    const netCost = totalLoanCost - totalSavings;

    setResults({
      monthlyPayment: monthlyPayment.toFixed(2),
      monthlySavings: monthlySavings.toFixed(2),
      netMonthlyDifference: (monthlySavings - monthlyPayment).toFixed(2),
      totalLoanCost: totalLoanCost.toFixed(2),
      totalSavings: totalSavings.toFixed(2),
      netCost: netCost.toFixed(2),
    });

    setChartData([
      {
        category: "Monthly Comparison",
        loanPayment: parseFloat(monthlyPayment.toFixed(2)),
        solarSavings: parseFloat(monthlySavings.toFixed(2))
      }
    ]);
  };

  // Add useEffect for initial calculation
  useEffect(() => {
    calculateResults();
  }, []);

  // Theming classes
  const containerBg = isDarkMode ? "bg-blue-900 text-blue-100" : "bg-amber-50 text-amber-900";
  const cardBg = isDarkMode ? "bg-blue-800" : "bg-amber-100";
  const headingColor = isDarkMode ? "text-yellow-400" : "text-amber-800";
  const inputBg = isDarkMode ? "bg-blue-700" : "bg-white";
  const borderColor = isDarkMode ? "border-blue-500" : "border-amber-300";
  const iconHoverColor = isDarkMode ? "hover:text-yellow-300" : "hover:text-amber-600";
  const buttonBg = isDarkMode ? "bg-green-600 hover:bg-green-700" : "bg-green-500 hover:bg-green-600";

  // Enhanced SEO structured data
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "FinancialProduct",
    "name": "Solar Financing & Loan Calculator",
    "description": "Calculate and compare solar loan payments with potential energy savings. Includes monthly payment breakdown, total cost analysis, and visual comparison charts.",
    "provider": {
      "@type": "Organization",
      "name": "Solar Insight"
    },
    "category": "Solar Energy Financing",
    "featureList": [
      "Monthly payment calculation",
      "Solar savings comparison",
      "Visual payment vs savings chart",
      "Total cost analysis"
    ]
  };

  return (
    <div className={`min-h-screen p-4 ${containerBg}`}>
      <Helmet>
        <title>Solar Loan Calculator | Compare Payments vs Savings | Solar Insight</title>
        <meta
          name="description"
          content="Calculate your solar loan payments and compare them with potential energy savings. Interactive calculator with visual charts to help you make informed solar financing decisions."
        />
        <meta name="keywords" content="solar loan calculator, solar financing, solar savings, solar ROI, solar investment" />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>

      <div className="max-w-7xl mx-auto space-y-8">
        {/* Header */}
        <div>
          <h1 className={`text-3xl font-bold mb-4 flex items-center ${headingColor}`}>
            <Calculator className={`mr-2 ${iconHoverColor}`} />
            Solar Financing Calculator
          </h1>
          <p className="text-lg">
            Compare your solar loan payments with potential energy savings to see if going solar makes financial sense.
          </p>
        </div>

        {/* Input Section */}
        <div className={`p-6 rounded-lg shadow-lg ${cardBg}`}>
          <h2 className={`text-xl font-bold mb-6 ${headingColor}`}>Loan & Savings Details</h2>
          <div className="grid grid-cols-1 gap-8">
            <SliderInput
              label="Loan Amount (ZAR)"
              name="loanAmount"
              value={inputs.loanAmount}
              onChange={handleChange}
              {...inputRanges.loanAmount}
              tooltip="Total cost of the solar system installation"
              bg={inputBg}
              borderColor={borderColor}
              iconHoverColor={iconHoverColor}
              formatter={(value) => `R${value.toLocaleString()}`}
            />
            <SliderInput
              label="Interest Rate (%)"
              name="interestRate"
              value={inputs.interestRate}
              onChange={handleChange}
              {...inputRanges.interestRate}
              tooltip="Annual interest rate for the solar loan"
              bg={inputBg}
              borderColor={borderColor}
              iconHoverColor={iconHoverColor}
              formatter={(value) => `${value}%`}
            />
            <SliderInput
              label="Payback Term (Years)"
              name="paybackTerm"
              value={inputs.paybackTerm}
              onChange={handleChange}
              {...inputRanges.paybackTerm}
              tooltip="Duration of the loan in years"
              bg={inputBg}
              borderColor={borderColor}
              iconHoverColor={iconHoverColor}
              formatter={(value) => `${value} years`}
            />
            <SliderInput
              label="Monthly Savings (ZAR)"
              name="monthlySavings"
              value={inputs.monthlySavings}
              onChange={handleChange}
              {...inputRanges.monthlySavings}
              tooltip="Estimated monthly savings from solar energy"
              bg={inputBg}
              borderColor={borderColor}
              iconHoverColor={iconHoverColor}
              formatter={(value) => `R${value.toLocaleString()}`}
            />
          </div>
          <button
            onClick={calculateResults}
            className={`mt-8 px-6 py-3 rounded-lg font-semibold text-white ${buttonBg} transition-colors flex items-center justify-center w-full md:w-auto`}
          >
            <RefreshCw className="mr-2 h-5 w-5" />
            Calculate Results
          </button>
        </div>

        {/* Results Section */}
        {results && (
          <div className={`p-6 rounded-lg shadow-lg ${cardBg}`}>
            <h2 className={`text-xl font-bold mb-6 flex items-center ${headingColor}`}>
              <TrendingUp className={`mr-2 ${iconHoverColor}`} />
              Financial Summary
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <ResultItem
                icon={<DollarSign className={iconHoverColor} />}
                label="Monthly Loan Payment"
                value={`R${parseFloat(results.monthlyPayment).toLocaleString()}`}
                description="Your fixed monthly loan installment"
              />
              <ResultItem
                icon={<DollarSign className={iconHoverColor} />}
                label="Monthly Savings"
                value={`R${parseFloat(results.monthlySavings).toLocaleString()}`}
                description="Expected monthly energy savings"
              />
              <ResultItem
                icon={<ArrowRight className={iconHoverColor} />}
                label="Net Monthly Impact"
                value={`R${parseFloat(results.netMonthlyDifference).toLocaleString()}`}
                description={parseFloat(results.netMonthlyDifference) >= 0 ? 
                  "You're cash-positive monthly!" : 
                  "Additional monthly cost"}
                valueColor={parseFloat(results.netMonthlyDifference) >= 0 ? 
                  "text-green-500" : "text-red-500"}
              />
              <ResultItem
                icon={<Info className={iconHoverColor} />}
                label="Total Net Impact"
                value={`R${parseFloat(results.netCost).toLocaleString()}`}
                description={parseFloat(results.netCost) <= 0 ? 
                  "Total savings over loan term" : 
                  "Total cost over loan term"}
                valueColor={parseFloat(results.netCost) <= 0 ? 
                  "text-green-500" : "text-red-500"}
              />
            </div>
          </div>
        )}

        {/* Chart Section */}
        {chartData.length > 0 && (
          <div className={`p-6 rounded-lg shadow-lg ${cardBg}`}>
            <h2 className={`text-xl font-bold mb-4 flex items-center ${headingColor}`}>
              <TrendingUp className={`mr-2 ${iconHoverColor}`} />
              Monthly Payment vs. Savings Comparison
            </h2>
            <p className="mb-6">
              This chart visualizes how your monthly loan payment compares to your expected solar savings.
              {parseFloat(results?.netMonthlyDifference) >= 0 ? 
                " You're currently cash-positive, with savings exceeding loan payments!" : 
                " Your loan payments currently exceed your savings."}
            </p>
            <div className="h-[400px] w-full">
              <CostComparisonChart data={chartData} isDarkMode={isDarkMode} />
            </div>
            <div className="mt-4 p-4 bg-opacity-50 rounded-lg border border-opacity-20 border-current">
              <h3 className="font-semibold mb-2">Understanding the Chart</h3>
              <ul className="list-disc list-inside space-y-2 text-sm">
                <li>Red bar shows your monthly loan payment</li>
                <li>Green bar shows your expected monthly savings</li>
                <li>Higher green bar means you're saving more than you're paying</li>
                <li>Hover over bars to see exact values</li>
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

// Slider Input component
const SliderInput = ({
  label,
  name,
  value,
  onChange,
  min,
  max,
  step,
  tooltip,
  bg,
  borderColor,
  iconHoverColor,
  formatter
}) => {
  return (
    <div className="relative group">
      <div className="flex justify-between items-center mb-2">
        <label className="font-semibold flex items-center">
          {label}
          <Info size={16} className={`ml-2 cursor-help ${iconHoverColor}`} />
        </label>
        <span className="font-mono">{formatter(value)}</span>
      </div>
      <input
        type="range"
        name={name}
        value={value}
        onChange={onChange}
        min={min}
        max={max}
        step={step}
        className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-green-500"
      />
      <div className="flex justify-between text-sm mt-1 opacity-60">
        <span>{formatter(min)}</span>
        <span>{formatter(max)}</span>
      </div>
      {tooltip && (
        <div className="absolute z-10 p-2 rounded bg-gray-800 text-white text-sm opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none -top-8 left-1/2 transform -translate-x-1/2">
          {tooltip}
        </div>
      )}
    </div>
  );
};

// Result Item component
const ResultItem = ({ icon, label, value, description, valueColor = "" }) => {
  return (
    <div className="flex items-start space-x-3">
      <div className="mt-1">{icon}</div>
      <div>
        <p className="font-semibold">{label}</p>
        <p className={`text-lg font-bold ${valueColor}`}>{value}</p>
        <p className="text-sm opacity-80">{description}</p>
      </div>
    </div>
  );
};

// Chart component
const CostComparisonChart = ({ data, isDarkMode }) => {
  const gridColor = isDarkMode ? "rgba(156, 163, 175, 0.2)" : "#e2e8f0";
  const axisTextColor = isDarkMode ? "#fbbf24" : "#374151";

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={data}
        margin={{ top: 20, right: 30, left: 40, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" stroke={gridColor} vertical={false} />
        <XAxis 
          dataKey="category" 
          stroke={axisTextColor} 
          tick={{ fill: axisTextColor }}
        />
        <YAxis 
          stroke={axisTextColor}
          tick={{ fill: axisTextColor }}
          tickFormatter={(value) => `R${value.toLocaleString()}`}
        />
        <Tooltip
          formatter={(value) => [`R${value.toLocaleString()}`, "Amount"]}
          contentStyle={{
            backgroundColor: isDarkMode ? "#1f2937" : "#fff",
            borderColor: isDarkMode ? "#4b5563" : "#e2e8f0",
            color: axisTextColor,
            borderRadius: 4,
            padding: 8
          }}
        />
        <Legend 
          wrapperStyle={{ 
            color: axisTextColor,
            padding: "20px"
          }}
        />
        <Bar
          dataKey="loanPayment"
          name="Loan Payment"
          fill={isDarkMode ? "#f87171" : "#ef4444"}
          radius={[4, 4, 0, 0]}
        />
        <Bar
          dataKey="solarSavings"
          name="Solar Savings"
          fill={isDarkMode ? "#34d399" : "#10b981"}
          radius={[4, 4, 0, 0]}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default SolarFinancingCalculator; 