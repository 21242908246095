import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { Battery } from 'lucide-react';
import SEO from '../SEO';

const BatteryPage = () => {
  const { isDarkMode } = useTheme();

  const types = [
    { name: 'Lithium-Ion Batteries', description: 'Popular for home installations, long lifespan, ideal for frequent use during load shedding' },
    { name: 'Lead-Acid Batteries', description: 'Lower upfront cost, require more maintenance' },
  ];

  const features = [
    'Capacity (kWh)',
    'Depth of Discharge (DoD)',
    'Cycle life',
    'Compatibility with SA\'s electrical standards',
  ];

  const brands = ['Freedom Won', 'Pylontech', 'Hubble', 'Dyness'];

  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "Product",
    "name": "Solar Batteries | Solar Insight",
    "description": "High-capacity solar batteries for reliable energy storage",
    "category": "Solar Batteries",
    "brand": {
      "@type": "Brand",
      "name": "Solar Insight"
    },
    "offers": {
      "@type": "AggregateOffer",
      "priceCurrency": "ZAR",
      "lowPrice": "15000",
      "highPrice": "100000",
      "offerCount": "10"
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.8",
      "reviewCount": "150"
    }
  };

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-blue-900 text-blue-100' : 'bg-amber-50 text-amber-900'}`}>
      <SEO 
        title="Solar Batteries & Energy Storage"
        description="Explore our range of high-capacity solar batteries and energy storage solutions. Find the perfect battery system for your solar setup."
        keywords="solar batteries, energy storage, battery backup, lithium-ion batteries, lead-acid batteries, solar power storage, South Africa"
        canonicalUrl="https://solarinsight.co.za/products/batteries"
        ogType="product"
        jsonLd={jsonLd}
      />
      
      <main className="container mx-auto px-4 py-8">
        <div className="flex items-center mb-8">
          <Battery className={`w-10 h-10 mr-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-500'}`} />
          <h1 className={`text-4xl font-bold ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>
            Solar Battery Storage Solutions
          </h1>
        </div>

        <p className="mb-8 text-lg">
          Solar batteries store excess energy for use during nighttime or power outages, providing reliable backup power for your home.
        </p>

        <section className="mb-8">
          <h2 className={`text-2xl font-semibold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>
            Types of Batteries
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {types.map((type, index) => (
              <div key={index} className={`p-4 rounded-lg ${isDarkMode ? 'bg-blue-800' : 'bg-white'} shadow-md`}>
                <h3 className="text-xl font-semibold mb-2">{type.name}</h3>
                <p>{type.description}</p>
              </div>
            ))}
          </div>
        </section>

        <section className="mb-8">
          <h2 className={`text-2xl font-semibold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>
            Key Features
          </h2>
          <ul className="list-disc list-inside">
            {features.map((feature, index) => (
              <li key={index} className="mb-2">{feature}</li>
            ))}
          </ul>
        </section>

        <section className="mb-8">
          <h2 className={`text-2xl font-semibold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>
            Top Brands in SA
          </h2>
          <div className="flex flex-wrap">
            {brands.map((brand, index) => (
              <span key={index} className={`mr-4 mb-2 px-3 py-1 rounded-full ${isDarkMode ? 'bg-blue-800' : 'bg-amber-200'}`}>
                {brand}
              </span>
            ))}
          </div>
        </section>

        <section>
          <h2 className={`text-2xl font-semibold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>
            Price Range
          </h2>
          <p className="text-lg font-semibold">
            R50,000 - R150,000 for a home battery system
          </p>
        </section>
      </main>
    </div>
  );
};

export default BatteryPage;