import React from 'react';
import { Link } from 'react-router-dom';
import { Brush, Wrench, Settings, ChevronRight } from 'lucide-react';
import { useTheme } from '../../contexts/ThemeContext';
import SEO from '../SEO';

const SolarServicePage = () => {
  const { isDarkMode } = useTheme();

  // Structured data for better SEO
  const servicesStructuredData = {
    "@context": "https://schema.org",
    "@type": "ItemList",
    "itemListElement": [
      {
        "@type": "Service",
        "position": 1,
        "name": "Solar Panel Cleaning",
        "description": "Professional solar panel cleaning services across South Africa",
        "areaServed": ["Cape Town", "Johannesburg", "Durban", "Pretoria"],
        "url": "https://solarinsight.co.za/services/cleaning"
      },
      {
        "@type": "Service",
        "position": 2,
        "name": "Solar System Repair",
        "description": "Expert solar repair and maintenance services",
        "areaServed": ["Cape Town", "Johannesburg", "Durban", "Pretoria"],
        "url": "https://solarinsight.co.za/services/repair"
      },
      {
        "@type": "Service",
        "position": 3,
        "name": "Solar Maintenance",
        "description": "Comprehensive solar system maintenance and optimization",
        "areaServed": ["Cape Town", "Johannesburg", "Durban", "Pretoria"],
        "url": "https://solarinsight.co.za/services/maintenance"
      }
    ]
  };

  const services = [
    {
      title: "Solar Panel Cleaning",
      description: "Professional cleaning services to maintain optimal panel efficiency",
      icon: <Brush className="w-8 h-8" />,
      link: "/services/cleaning",
      features: [
        "Eco-friendly cleaning methods",
        "Improved energy production",
        "Extended panel lifespan",
        "Professional equipment"
      ]
    },
    {
      title: "Solar System Repair",
      description: "Expert diagnostics and repair services for all solar components",
      icon: <Wrench className="w-8 h-8" />,
      link: "/services/repair",
      features: [
        "Quick response times",
        "Certified technicians",
        "Warranty-compliant repairs",
        "Quality replacement parts"
      ]
    },
    {
      title: "Solar Maintenance",
      description: "Regular maintenance to ensure optimal system performance",
      icon: <Settings className="w-8 h-8" />,
      link: "/services/maintenance",
      features: [
        "Scheduled inspections",
        "Performance optimization",
        "Preventive maintenance",
        "Detailed reporting"
      ]
    }
  ];

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-blue-900 text-blue-100' : 'bg-amber-50 text-amber-900'}`}>
      <SEO
        title="Solar Services in South Africa | Professional Maintenance & Repair"
        description="Comprehensive solar services including cleaning, repair, and maintenance across South Africa. Expert solutions for optimal solar system performance."
        keywords="solar services South Africa, panel cleaning, solar repair, solar maintenance, Johannesburg solar, Cape Town solar"
        jsonLd={servicesStructuredData}
      />

      <main className="container mx-auto px-4 py-8">
        {/* Hero Section */}
        <section className={`text-center py-16 ${isDarkMode ? 'bg-blue-800' : 'bg-amber-800'} rounded-lg shadow-xl mb-12`}>
          <h1 className={`
            text-4xl md:text-6xl font-bold mb-6
            ${isDarkMode ? 'text-yellow-400' : 'text-yellow-400'}
            drop-shadow-[0_0_10px_rgba(250,204,21,0.7)]
          `}>
            Solar Services
          </h1>
          <p className="text-xl text-gray-200 mb-8 max-w-2xl mx-auto">
            Professional solar system maintenance, repair, and cleaning services across South Africa
          </p>
        </section>

        {/* Services Grid */}
        <section className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
          {services.map((service, index) => (
            <Link
              key={index}
              to={service.link}
              className={`
                p-6 rounded-lg shadow-xl transition-all duration-300
                ${isDarkMode ? 'bg-blue-800 hover:bg-blue-700' : 'bg-amber-800 hover:bg-amber-700'}
                transform hover:scale-105
              `}
            >
              <div className="flex items-center mb-4 text-yellow-400">
                {service.icon}
                <h2 className="text-xl font-semibold ml-3 text-white">{service.title}</h2>
              </div>
              <p className="text-gray-300 mb-4">{service.description}</p>
              <ul className="space-y-2">
                {service.features.map((feature, idx) => (
                  <li key={idx} className="flex items-center text-gray-300">
                    <ChevronRight className="w-4 h-4 text-yellow-400 mr-2" />
                    <span className="text-sm">{feature}</span>
                  </li>
                ))}
              </ul>
            </Link>
          ))}
        </section>

        {/* Coverage Section */}
        <section className={`p-8 rounded-lg shadow-xl mb-12 ${isDarkMode ? 'bg-blue-800' : 'bg-amber-800'}`}>
          <h2 className="text-2xl font-bold mb-4 text-white">Service Coverage</h2>
          <p className="text-gray-300 mb-4">
            We provide comprehensive solar services across major South African cities and surrounding areas:
          </p>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            {["Cape Town", "Johannesburg", "Durban", "Pretoria"].map((city, index) => (
              <div key={index} className="text-center p-4 bg-opacity-20 bg-white rounded-lg">
                <span className="text-yellow-400 font-semibold">{city}</span>
              </div>
            ))}
          </div>
        </section>

        {/* CTA Section */}
        <section className={`text-center p-8 rounded-lg shadow-xl ${isDarkMode ? 'bg-blue-800' : 'bg-amber-800'}`}>
          <h2 className="text-2xl font-bold mb-4 text-white">Ready to Get Started?</h2>
          <p className="text-gray-300 mb-6">
            Contact us today to schedule a service or learn more about our maintenance packages
          </p>
          <Link
            to="/contact"
            className={`
              inline-block px-8 py-3 rounded-lg font-semibold text-lg
              ${isDarkMode ? 'bg-yellow-400 text-gray-900' : 'bg-yellow-400 text-gray-900'}
              hover:bg-yellow-300 transition duration-300
              transform hover:scale-105
            `}
          >
            Contact Us
          </Link>
        </section>
      </main>
    </div>
  );
};

export default SolarServicePage; 