import { 
  createDocument, 
  queryDocuments, 
  updateDocument, 
  deleteDocument,
  getDocument
} from './firebase';
import { serverTimestamp } from 'firebase/firestore';

const REVIEWS_COLLECTION = 'reviews';

export const addReview = async (reviewData) => {
  try {
    const reviewWithTimestamp = {
      ...reviewData,
      createdAt: serverTimestamp(),
      status: 'pending', // pending, approved, rejected
      likes: 0,
      helpful: 0,
      verified: false
    };
    const docId = await createDocument(REVIEWS_COLLECTION, reviewWithTimestamp);
    return { id: docId, ...reviewWithTimestamp };
  } catch (error) {
    console.error('Error adding review:', error);
    throw error;
  }
};

export const getReviews = async (productId = null, status = 'approved') => {
  try {
    const conditions = [
      { field: 'status', operator: '==', value: status }
    ];

    if (productId) {
      conditions.push({ field: 'productId', operator: '==', value: productId });
    }

    const orderByField = { field: 'createdAt', direction: 'desc' };
    
    const reviews = await queryDocuments(REVIEWS_COLLECTION, conditions, orderByField);
    return reviews.map(review => ({
      ...review,
      createdAt: review.createdAt?.toDate()
    }));
  } catch (error) {
    console.error('Error getting reviews:', error);
    throw error;
  }
};

export const updateReviewStatus = async (reviewId, status) => {
  try {
    await updateDocument(REVIEWS_COLLECTION, reviewId, { status });
  } catch (error) {
    console.error('Error updating review status:', error);
    throw error;
  }
};

export const markReviewHelpful = async (reviewId) => {
  try {
    // Since we don't have a direct increment function in our utilities,
    // we'll first get the current count and increment it
    const review = await getDocument(REVIEWS_COLLECTION, reviewId);
    const currentHelpful = review?.helpful || 0;
    await updateDocument(REVIEWS_COLLECTION, reviewId, {
      helpful: currentHelpful + 1
    });
  } catch (error) {
    console.error('Error marking review as helpful:', error);
    throw error;
  }
};

export const deleteReview = async (reviewId) => {
  try {
    await deleteDocument(REVIEWS_COLLECTION, reviewId);
  } catch (error) {
    console.error('Error deleting review:', error);
    throw error;
  }
}; 