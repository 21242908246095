// Load shedding service to handle API calls through proxy
import axios from 'axios';

const API_BASE_URL = '/api/esp';

const loadSheddingService = {
  getStatus: async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/status`);
      return response.data;
    } catch (error) {
      console.error('Error fetching load shedding status:', error);
      throw error;
    }
  },

  getAreaSearch: async (searchText) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/areas_search`, {
        params: { text: searchText }
      });
      return response.data;
    } catch (error) {
      console.error('Error searching areas:', error);
      throw error;
    }
  },

  getAreaSchedule: async (id) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/area`, {
        params: { id }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching area schedule:', error);
      throw error;
    }
  }
};

export default loadSheddingService; 