import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import Header from './Header';
import { useTheme } from '../../contexts/ThemeContext';

const Layout = ({ children }) => {
  const { isDarkMode } = useTheme();
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const mainClass = isDarkMode
    ? 'bg-blue-900 text-blue-100'
    : 'bg-amber-50 text-amber-900';

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }

    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, [isMobileMenuOpen]);

  return (
    <div className={`flex min-h-screen ${mainClass}`}>
      {/* Overlay for mobile menu */}
      {isMobileMenuOpen && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-40 lg:hidden"
          onClick={() => setIsMobileMenuOpen(false)}
        />
      )}

      {/* Sidebar */}
      <Sidebar 
        isExpanded={isSidebarExpanded}
        setIsExpanded={setIsSidebarExpanded}
        isMobileMenuOpen={isMobileMenuOpen}
        closeMobileMenu={() => setIsMobileMenuOpen(false)}
      />

      {/* Main content */}
      <div className={`
        flex-1 min-h-screen
        transition-all duration-300 ease-in-out
        ${isSidebarExpanded ? 'lg:ml-32' : 'lg:ml-12'}
      `}>
        <Header 
          toggleSidebar={() => setIsSidebarExpanded(!isSidebarExpanded)}
          toggleMobileMenu={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          isSidebarExpanded={isSidebarExpanded}
        />
        
        <main className="pt-16 min-h-screen">
          <div className="container mx-auto px-2 sm:px-4 lg:px-6 pt-8">
            {children}
          </div>
        </main>
      </div>
    </div>
  );
};

export default Layout;