import React, { useState, useEffect } from "react";
import { useTheme } from "../../contexts/ThemeContext";
import { Helmet } from "react-helmet";
import {
  Calculator,
  Sun,
  HelpCircle,
  Info,
  DollarSign,
  TrendingUp,
  ArrowRight
} from "lucide-react";
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell
} from "recharts";

const SolarGeyserCalculator = () => {
  const { isDarkMode } = useTheme();

  // Initial state for user inputs
  const [inputs, setInputs] = useState({
    monthlyBill: 2000,            // in ZAR
    waterHeatingPercent: 40,      // 30-50% typical
    solarGeyserEfficiency: 70,    // 50-80% typical
    eskomRebate: 3000,            // Eskom rebate
    installationCost: 25000       // Typical cost of solar geyser
  });

  // Calculation results
  const [results, setResults] = useState(null);
  // Chart data for monthly cost comparison
  const [chartData, setChartData] = useState(null);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((prev) => ({
      ...prev,
      [name]: parseFloat(value)
    }));
  };

  // Compute results whenever inputs change
  useEffect(() => {
    calculateResults();
  }, [inputs]);

  const calculateResults = () => {
    const { 
      monthlyBill, 
      waterHeatingPercent, 
      solarGeyserEfficiency, 
      eskomRebate, 
      installationCost 
    } = inputs;

    // 1. Monthly water heating cost (before solar)
    const monthlyWaterHeatingCost = (monthlyBill * waterHeatingPercent) / 100;

    // 2. Monthly savings with solar geyser
    const monthlySavings = (monthlyWaterHeatingCost * solarGeyserEfficiency) / 100;

    // 3. Monthly cost after solar geyser installation
    const monthlyCostAfter = monthlyWaterHeatingCost - monthlySavings;

    // 4. Annual savings
    const annualSavings = monthlySavings * 12;

    // 5. Payback period
    const netCost = installationCost - eskomRebate;
    const paybackYears = netCost > 0 ? netCost / annualSavings : 0;

    setResults({
      monthlyWaterHeatingCost: monthlyWaterHeatingCost.toFixed(2),
      monthlySavings: monthlySavings.toFixed(2),
      annualSavings: annualSavings.toFixed(2),
      paybackYears: paybackYears.toFixed(2)
    });

    // Update chart data with properly formatted values
    setChartData([
      { 
        name: "Current Cost", 
        cost: Number(monthlyWaterHeatingCost.toFixed(2)),
        fill: isDarkMode ? "#ef4444" : "#dc2626" // Red shade
      },
      { 
        name: "Cost with Solar", 
        cost: Number(monthlyCostAfter.toFixed(2)),
        fill: isDarkMode ? "#22c55e" : "#16a34a" // Green shade
      }
    ]);
  };

  // Theming classes
  const containerBg = isDarkMode ? "bg-blue-900 text-blue-100" : "bg-amber-50 text-amber-900";
  const cardBg = isDarkMode ? "bg-blue-800" : "bg-amber-100";
  const headingColor = isDarkMode ? "text-yellow-400" : "text-amber-800";
  const labelText = isDarkMode ? "text-amber-100" : "text-amber-900";
  const inputBg = isDarkMode ? "bg-blue-700" : "bg-white";
  const borderColor = isDarkMode ? "border-blue-500" : "border-amber-300";
  const iconHoverColor = isDarkMode ? "hover:text-yellow-300" : "hover:text-amber-600";

  // FAQ data
  const faq = [
    {
      q: "How much can I save with a solar geyser?",
      a: "Solar geysers can reduce water-heating costs by 50–80%, depending on your location, usage patterns, and system efficiency."
    },
    {
      q: "Does Eskom still offer rebates for solar geysers?",
      a: "Eskom offers rebates ranging from ~ZAR 1,900 to ~ZAR 4,900, depending on the system type and size. Verify current rebate programs as they may change over time."
    },
    {
      q: "What is the typical payback period?",
      a: "Payback can range from 2–5 years based on installation cost, electricity rate, and your water heating usage."
    }
  ];

  /**
   * Structured Data (JSON-LD) for SEO referencing "Solar Geyser Services" 
   */
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Service",
    "name": "Solar Geyser Savings Calculator",
    "description": "Estimate how much you can save by switching from an electric geyser to a solar geyser in South Africa. Calculate monthly savings, annual savings, and payback period.",
    "provider": {
      "@type": "LocalBusiness",
      "name": "Solar Insight"
    },
    "areaServed": ["South Africa"],
    "additionalType": "http://www.productontology.org/doc/Solar_geyser",
    "offers": {
      "@type": "Offer",
      "price": "Varies based on system specs",
      "priceCurrency": "ZAR",
      "url": "https://solarinsight.co.za/services/solar-geyser"
    }
  };

  return (
    <div className={`min-h-screen p-4 ${containerBg}`}>
      <Helmet>
        <title>Solar Geyser Savings Calculator | Solar Insight</title>
        <meta
          name="description"
          content="Estimate how much you can save by switching from an electric geyser to a solar geyser in South Africa. Calculate monthly savings, annual savings, and payback period."
        />
        {/* Structured data script */}
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>

      <h1 className={`text-3xl font-bold mb-4 flex items-center ${headingColor}`}>
        <Sun className={`mr-2 ${iconHoverColor}`} />
        Solar Geyser Savings Calculator
      </h1>

      <p className="mb-6">
        Use this calculator to estimate your potential savings, payback period, and 
        monthly cost reduction when switching from an electric geyser to a solar geyser.
      </p>

      {/* Inputs Section */}
      <div className={`p-4 mb-6 rounded shadow-md ${cardBg}`}>
        <h2 className={`text-xl font-bold mb-4 ${headingColor}`}>Your Details</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <InputField
            label="Monthly Electricity Bill (ZAR)"
            name="monthlyBill"
            value={inputs.monthlyBill}
            onChange={handleChange}
            min="0"
            tooltip="Your total monthly electricity bill."
            bg={inputBg}
            borderColor={borderColor}
            iconHoverColor={iconHoverColor}
          />
          <InputField
            label="Water Heating % of Bill"
            name="waterHeatingPercent"
            value={inputs.waterHeatingPercent}
            onChange={handleChange}
            min="10"
            max="100"
            tooltip="Typically between 30–50% of your monthly bill."
            bg={inputBg}
            borderColor={borderColor}
            iconHoverColor={iconHoverColor}
          />
          <InputField
            label="Solar Geyser Efficiency (%)"
            name="solarGeyserEfficiency"
            value={inputs.solarGeyserEfficiency}
            onChange={handleChange}
            min="10"
            max="100"
            tooltip="Solar geysers can offset 50–80% of water heating costs."
            bg={inputBg}
            borderColor={borderColor}
            iconHoverColor={iconHoverColor}
          />
          <InputField
            label="Eskom Rebate (ZAR)"
            name="eskomRebate"
            value={inputs.eskomRebate}
            onChange={handleChange}
            min="0"
            tooltip="Approximate Eskom rebate for solar geysers."
            bg={inputBg}
            borderColor={borderColor}
            iconHoverColor={iconHoverColor}
          />
          <InputField
            label="Installation Cost (ZAR)"
            name="installationCost"
            value={inputs.installationCost}
            onChange={handleChange}
            min="0"
            tooltip="Total cost of solar geyser installation."
            bg={inputBg}
            borderColor={borderColor}
            iconHoverColor={iconHoverColor}
          />
        </div>
      </div>

      {/* Results Section */}
      {results && (
        <div className={`p-4 mb-8 rounded shadow-md ${cardBg}`}>
          <h2 className={`text-xl font-bold mb-4 flex items-center ${headingColor}`}>
            <Calculator className={`mr-2 ${iconHoverColor}`} />
            Savings Breakdown
          </h2>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <ResultItem
              icon={<DollarSign className={`${iconHoverColor}`} />}
              label="Monthly Water Heating Cost"
              value={`R${results.monthlyWaterHeatingCost}/month`}
              description="Your current monthly spending on water heating"
            />
            <ResultItem
              icon={<TrendingUp className={`${iconHoverColor}`} />}
              label="Monthly Savings with Solar"
              value={`R${results.monthlySavings}/month`}
              description="How much you'd save each month after switching"
            />
            <ResultItem
              icon={<DollarSign className={`${iconHoverColor}`} />}
              label="Annual Savings"
              value={`R${results.annualSavings}/year`}
              description="Total estimated yearly savings"
            />
            <ResultItem
              icon={<Info className={`${iconHoverColor}`} />}
              label="Payback Period"
              value={`${results.paybackYears} years`}
              description="Time to recover installation costs"
            />
          </div>
        </div>
      )}

      {/* Chart Section */}
      {chartData && (
        <section aria-label="Cost Comparison Chart" className={`p-4 mb-8 rounded-lg shadow-lg ${cardBg}`}>
          {/* Chart Header */}
          <header className="mb-6">
            <h2 className={`text-2xl font-bold mb-3 flex items-center ${headingColor}`}>
              <TrendingUp className={`mr-3 h-6 w-6 ${iconHoverColor}`} /> 
              Solar Geyser Cost Comparison
            </h2>
            <p className={`text-base ${labelText}`}>
              Visual comparison of your monthly water heating costs before and after installing a solar geyser. 
              The chart shows potential savings based on your input values.
            </p>
          </header>

          {/* Chart Container with Fixed Height */}
          <div className="w-full" style={{ minHeight: "400px" }}>
            <div className="chart-container relative w-full h-[400px]">
              <CostComparisonChart data={chartData} isDarkMode={isDarkMode} />
            </div>
          </div>

          {/* Chart Legend and Additional Info */}
          <div className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className={`p-4 rounded-lg ${isDarkMode ? 'bg-blue-700/50' : 'bg-amber-100/50'}`}>
              <h3 className={`text-lg font-semibold mb-2 ${headingColor}`}>Current Cost</h3>
              <p className={labelText}>
                Your estimated monthly water heating cost without a solar geyser: 
                <span className="font-bold text-red-500 ml-2">
                  R{chartData[0].cost.toFixed(2)}
                </span>
              </p>
            </div>
            <div className={`p-4 rounded-lg ${isDarkMode ? 'bg-blue-700/50' : 'bg-amber-100/50'}`}>
              <h3 className={`text-lg font-semibold mb-2 ${headingColor}`}>Cost with Solar</h3>
              <p className={labelText}>
                Your estimated monthly cost with a solar geyser: 
                <span className="font-bold text-green-500 ml-2">
                  R{chartData[1].cost.toFixed(2)}
                </span>
              </p>
            </div>
          </div>

          {/* SEO-friendly Chart Description */}
          <div className="sr-only" aria-hidden="true">
            Compare monthly water heating costs: Current cost R{chartData[0].cost.toFixed(2)} vs 
            Solar Geyser cost R{chartData[1].cost.toFixed(2)}. 
            Potential monthly savings of R{(chartData[0].cost - chartData[1].cost).toFixed(2)}.
          </div>
        </section>
      )}

      {/* FAQ Section */}
      <div className={`p-4 rounded shadow-md ${cardBg}`}>
        <h3 className={`text-xl font-bold mb-3 ${headingColor}`}>Frequently Asked Questions</h3>
        {faq.map((item, idx) => (
          <FAQItem
            key={idx}
            question={item.q}
            answer={item.a}
            isDarkMode={isDarkMode}
            iconHoverColor={iconHoverColor}
          />
        ))}
      </div>

      {/* Next Steps / Integration with Other Tools */}
      <div className={`p-4 mt-6 rounded shadow-md ${cardBg}`}>
        <h3 className={`text-xl font-bold mb-3 ${headingColor}`}>Next Steps</h3>
        <p className="mb-3">
          If you're satisfied with the estimated savings, consider exploring our Solar Geyser Packages 
          or contacting us for a detailed quote:
        </p>
        <div className="flex space-x-4">
          <a
            href="/services/solar-geyser"
            className={`px-4 py-2 rounded font-semibold ${
              isDarkMode ? 'bg-green-500 hover:bg-green-600 text-white' : 'bg-green-500 hover:bg-green-600 text-white'
            } transition-colors`}
          >
            View Solar Geyser Packages
          </a>
          <a
            href="/contact"
            className={`px-4 py-2 border-2 border-green-500 rounded font-semibold ${
              isDarkMode ? 'text-green-400 hover:bg-green-500 hover:text-white' : 'text-green-700 hover:bg-green-500 hover:text-white'
            } transition-colors`}
          >
            Contact Us
          </a>
        </div>
      </div>
    </div>
  );
};

/** 
 * Sub-component: InputField
 */
const InputField = ({
  label,
  name,
  value,
  onChange,
  min,
  max,
  tooltip,
  bg,
  borderColor,
  iconHoverColor
}) => {
  return (
    <div className="relative group">
      <label className="block mb-1 font-semibold flex items-center">
        {label}
        <HelpCircle size={16} className={`ml-2 cursor-help ${iconHoverColor}`} />
      </label>
      <input
        type="number"
        name={name}
        value={value}
        onChange={onChange}
        min={min}
        max={max}
        className={`w-full p-2 rounded border ${borderColor} ${bg}`}
      />
      {tooltip && (
        <div className="absolute z-10 p-2 rounded bg-gray-800 text-white text-sm opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none">
          {tooltip}
        </div>
      )}
    </div>
  );
};

/**
 * Sub-component: ResultItem
 */
const ResultItem = ({ icon, label, value, description }) => {
  return (
    <div className="flex items-start">
      <div className="mr-3 mt-1">
        {icon}
      </div>
      <div>
        <p className="font-semibold">{label}</p>
        <p className="text-lg font-bold">{value}</p>
        <p className="text-sm">{description}</p>
      </div>
    </div>
  );
};

/**
 * Chart sub-component for comparing monthly water heating cost
 */
const CostComparisonChart = ({ data, isDarkMode }) => {
  if (!data || data.length === 0) return null;

  // Chart theming
  const gridColor = isDarkMode ? "rgba(59, 130, 246, 0.2)" : "rgba(226, 232, 240, 0.5)";
  const textColor = isDarkMode ? "#ffd700" : "#475569";

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart 
        data={data}
        margin={{ top: 20, right: 30, left: 40, bottom: 20 }}
      >
        <CartesianGrid 
          strokeDasharray="3 3" 
          stroke={gridColor} 
          vertical={false}
        />
        <XAxis 
          dataKey="name" 
          stroke={textColor}
          tick={{ fill: textColor, fontSize: 12 }}
        />
        <YAxis 
          stroke={textColor}
          tick={{ fill: textColor, fontSize: 12 }}
          tickFormatter={(value) => `R${value}`}
        />
        <Tooltip
          formatter={(value) => [`R${value.toFixed(2)}`, "Monthly Cost"]}
          contentStyle={{
            backgroundColor: isDarkMode ? "#1e3a8a" : "#fff",
            borderColor: isDarkMode ? "#312e81" : "#e2e8f0",
            color: textColor,
            borderRadius: "4px",
            padding: "8px"
          }}
        />
        <Legend 
          wrapperStyle={{ 
            color: textColor,
            paddingTop: "20px"
          }}
        />
        <Bar 
          dataKey="cost" 
          name="Monthly Water Heating Cost (ZAR)"
          radius={[4, 4, 0, 0]}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.fill} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

/**
 * Sub-component: FAQItem
 */
const FAQItem = ({ question, answer, isDarkMode, iconHoverColor }) => {
  const [open, setOpen] = useState(false);
  const bg = isDarkMode ? "bg-blue-700" : "bg-amber-200";

  return (
    <div className="mb-3">
      <button
        className={`flex justify-between items-center w-full text-left font-semibold p-2 rounded mb-1 ${
          isDarkMode ? 'bg-blue-600 hover:bg-blue-500' : 'bg-amber-300 hover:bg-amber-400'
        } transition-colors`}
        onClick={() => setOpen(!open)}
      >
        {question}
        <ArrowRight className={`transform transition-transform ${open ? 'rotate-90' : ''} ${iconHoverColor}`} />
      </button>
      {open && (
        <div className={`p-3 rounded ${bg}`}>
          {answer}
        </div>
      )}
    </div>
  );
};

export default SolarGeyserCalculator; 