import { initializeApp, getApp, getApps } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics, isSupported } from 'firebase/analytics';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCGBvLbJ6lMapkmdKnPlwcxip8gB_D4mTM",
  authDomain: "solar-insight-sa.firebaseapp.com",
  projectId: "solar-insight-sa",
  storageBucket: "solar-insight-sa.appspot.com",
  messagingSenderId: "360227120626",
  appId: "1:360227120626:web:1b7b39a3b619ff6684b76b",
  measurementId: "G-4JWSGJJJVH"
};

// Initialize Firebase app first
let app;
if (!getApps().length) {
  app = initializeApp(firebaseConfig);
} else {
  app = getApp();
}

// Initialize services after app initialization
const auth = getAuth(app);
const db = getFirestore(app);
const googleProvider = new GoogleAuthProvider();
let analytics = null;

const initAnalytics = async () => {
  try {
    if (process.env.NODE_ENV === 'production' && typeof window !== 'undefined') {
      const analyticsSupported = await isSupported();
      if (analyticsSupported) {
        analytics = getAnalytics(app);
        console.log('Firebase Analytics initialized successfully');
      }
    }
  } catch (error) {
    console.error("Firebase Analytics initialization error:", error);
    analytics = null;
  }
};

// Initialize analytics
initAnalytics();

export { db, analytics, auth, googleProvider }; 