import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Bell, Search, User, ChevronDown, Menu, Sun, Moon } from 'lucide-react';
import { useTheme } from '../../contexts/ThemeContext';
import { useAuth } from '../auth/AuthContext';
import { signOut } from 'firebase/auth';
import { auth } from '../../firebase';

const Header = ({ toggleMobileMenu, toggleSidebar, isSidebarExpanded }) => {
  const { isDarkMode, toggleTheme } = useTheme();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [notifications] = useState(3);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/signin');
    } catch (error) {
      console.error('Failed to log out:', error);
    }
  };

  return (
    <div className={`
      fixed top-0 right-0 left-0 
      lg:left-[${isSidebarExpanded ? '16rem' : '5rem'}]
      transition-all duration-300 ease-in-out
      z-40
    `}>
      <header className={`
        h-16 
        ${isDarkMode ? 'bg-blue-800' : 'bg-amber-900'}
        shadow-lg
        flex items-center
      `}>
        <div className="h-full w-full px-4 flex items-center justify-between">
          {/* Left side */}
          <div className="flex items-center space-x-4">
            {/* Mobile menu button */}
            <button 
              className="lg:hidden p-2 rounded-md text-white hover:bg-opacity-75"
              onClick={toggleMobileMenu}
            >
              <Menu size={24} />
            </button>

            {/* Desktop sidebar toggle */}
            <button 
              className="hidden lg:block p-2 rounded-md text-white hover:bg-opacity-75"
              onClick={toggleSidebar}
            >
              <Menu size={24} />
            </button>

            <div className="flex items-center gap-2">
              <h1 className="text-lg lg:text-xl font-bold text-yellow-400 truncate">
                Solar Insight
              </h1>
              <button
                onClick={toggleTheme}
                className={`p-2 rounded-full transition-colors duration-200`}
                title={isDarkMode ? "Switch to Light Mode" : "Switch to Dark Mode"}
              >
                {isDarkMode ? (
                  <Sun size={24} className="text-yellow-400 animate-pulse-gentle" />
                ) : (
                  <Moon size={24} className="text-yellow-400 animate-pulse-gentle" />
                )}
              </button>
            </div>
          </div>

          {/* Right side items */}
          <div className="flex items-center space-x-2 lg:space-x-4">
            <SearchBar 
              isSearchOpen={isSearchOpen} 
              setIsSearchOpen={setIsSearchOpen}
              isDarkMode={isDarkMode}
            />
            {user && (
              <NotificationBell 
                notifications={notifications}
                hoverClass={isDarkMode ? 'hover:bg-blue-700' : 'hover:bg-amber-700'}
              />
            )}
            <UserMenu 
              isUserMenuOpen={isUserMenuOpen} 
              setIsUserMenuOpen={setIsUserMenuOpen} 
              isDarkMode={isDarkMode} 
              user={user}
              onLogout={handleLogout}
            />
          </div>
        </div>
      </header>
    </div>
  );
};

const SearchBar = ({ isSearchOpen, setIsSearchOpen, isDarkMode }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const searchRef = useRef(null);
  const navigate = useNavigate();

  // Handle search submission
  const handleSearch = (e) => {
    e.preventDefault();
    if (searchTerm.trim()) {
      navigate(`/search?q=${encodeURIComponent(searchTerm.trim())}`);
      setIsSearchOpen(false);
      setSearchTerm('');
    }
  };

  // Handle enter key press
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && searchTerm.trim()) {
      handleSearch(e);
    } else if (e.key === 'Escape') {
      setIsSearchOpen(false);
      setSearchTerm('');
    }
  };

  return (
    <div className="relative" ref={searchRef}>
      <button 
        className={`p-2 rounded-full ${isDarkMode ? 'hover:bg-blue-700' : 'hover:bg-amber-700'}`} 
        onClick={() => setIsSearchOpen(!isSearchOpen)}
        aria-label="Search"
      >
        <Search size={20} className="text-white" />
      </button>
      {isSearchOpen && (
        <form onSubmit={handleSearch} className="absolute right-0 top-full mt-2">
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Search..."
            className={`
              p-2 rounded
              w-48 sm:w-64 md:w-80
              ${isDarkMode 
                ? 'bg-blue-700 text-white placeholder-blue-300' 
                : 'bg-amber-700 text-white placeholder-amber-300'}
              focus:outline-none focus:ring-2 focus:ring-yellow-400
            `}
            autoFocus
          />
        </form>
      )}
    </div>
  );
};

const NotificationBell = ({ notifications, hoverClass }) => (
  <button className={`p-2 rounded-full ${hoverClass} relative`}>
    <Bell size={20} className="text-white" />
    {notifications > 0 && (
      <span className="absolute top-0 right-0 bg-red-500 text-white text-xs rounded-full h-5 w-5 flex items-center justify-center">
        {notifications}
      </span>
    )}
  </button>
);

const UserMenu = ({ isUserMenuOpen, setIsUserMenuOpen, isDarkMode, user, onLogout }) => (
  <div className="relative">
    <button 
      className={`flex items-center space-x-2 p-2 rounded ${isDarkMode ? 'hover:bg-blue-700' : 'hover:bg-amber-700'}`}
      onClick={() => setIsUserMenuOpen(!isUserMenuOpen)}
    >
      <User size={20} className="text-white" />
      <span className="text-white hidden sm:inline">{user ? user.email || 'User' : 'Guest'}</span>
      <ChevronDown size={16} className="text-white" />
    </button>
    {isUserMenuOpen && (
      <div className={`absolute right-0 mt-2 w-48 rounded-md shadow-lg ${isDarkMode ? 'bg-blue-700' : 'bg-amber-700'}`}>
        <div className="py-1">
          {user ? (
            <>
              <Link to="/profile" className={`block px-4 py-2 text-sm ${isDarkMode ? 'text-blue-200 hover:bg-blue-600' : 'text-amber-200 hover:bg-amber-600'}`}>Profile</Link>
              <Link to="/settings" className={`block px-4 py-2 text-sm ${isDarkMode ? 'text-blue-200 hover:bg-blue-600' : 'text-amber-200 hover:bg-amber-600'}`}>Settings</Link>
              <button 
                onClick={onLogout} 
                className={`block w-full text-left px-4 py-2 text-sm ${isDarkMode ? 'text-blue-200 hover:bg-blue-600' : 'text-amber-200 hover:bg-amber-600'}`}
              >
                Sign out
              </button>
            </>
          ) : (
            <Link to="/signin" className={`block px-4 py-2 text-sm ${isDarkMode ? 'text-blue-200 hover:bg-blue-600' : 'text-amber-200 hover:bg-amber-600'}`}>Sign in</Link>
          )}
        </div>
      </div>
    )}
  </div>
);

export default Header;