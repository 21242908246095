import React, { useState, useEffect } from 'react';
import { useTheme } from '../contexts/ThemeContext';
import { useAuth } from '../components/auth/AuthContext';
import { Helmet } from 'react-helmet';
import { Star, Search, X, Filter, Award, ThumbsUp, AlertTriangle, Check, Clock } from 'lucide-react';
import { getReviews, addReview, markReviewHelpful } from '../services/reviewService';
import { toast } from 'react-hot-toast';

const ReviewsPage = () => {
  const { isDarkMode } = useTheme();
  const { user } = useAuth();
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isWriteReviewOpen, setIsWriteReviewOpen] = useState(false);
  const [newReview, setNewReview] = useState({
    rating: 0,
    comment: '',
    pros: '',
    cons: '',
    installationDate: '',
    usageDuration: '',
    recommendProduct: true,
    images: []
  });
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [topProduct, setTopProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [helpfulReviews, setHelpfulReviews] = useState(new Set());

  const categories = [
    { id: 'all', name: 'All Products' },
    { id: 'panels', name: 'Solar Panels' },
    { id: 'inverters', name: 'Inverters' },
    { id: 'batteries', name: 'Batteries' },
  ];

  useEffect(() => {
    fetchProducts();
    fetchReviews();
  }, []);

  useEffect(() => {
    if (products.length > 0) {
      fetchTopProduct();
      if (!selectedProduct) {
        setSelectedProduct(products[0]);
      }
    }
  }, [products]);

  const fetchProducts = () => {
    // Simulated API call - replace with actual Firebase call later
    const sampleProducts = [
      { id: 1, name: 'SunPower X-Series Solar Panel', category: 'panels', avgRating: 4.5, reviewCount: 120 },
      { id: 2, name: 'Tesla Powerwall 2', category: 'batteries', avgRating: 4.7, reviewCount: 89 },
      { id: 3, name: 'Enphase IQ7+ Microinverter', category: 'inverters', avgRating: 4.6, reviewCount: 75 },
      { id: 4, name: 'LG NeON 2 Solar Panel', category: 'panels', avgRating: 4.4, reviewCount: 62 },
      { id: 5, name: 'SolarEdge HD-Wave Inverter', category: 'inverters', avgRating: 4.3, reviewCount: 55 },
      { id: 6, name: 'LG Chem RESU Battery', category: 'batteries', avgRating: 4.5, reviewCount: 43 },
    ];
    setProducts(sampleProducts);
  };

  const fetchReviews = async () => {
    try {
      setIsLoading(true);
      const fetchedReviews = await getReviews();
      setReviews(fetchedReviews);
    } catch (error) {
      console.error('Error fetching reviews:', error);
      toast.error('Failed to load reviews. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTopProduct = () => {
    const top = products.reduce((prev, current) => 
      (prev.avgRating > current.avgRating) ? prev : current
    );
    setTopProduct(top);
  };

  const handleProductSelect = (product) => {
    setSelectedProduct(product);
  };

  const handleWriteReview = () => {
    if (!user) {
      toast.error('Please log in to write a review.');
      return;
    }
    if (!selectedProduct) {
      toast.error('Please select a product to review.');
      return;
    }
    setIsWriteReviewOpen(true);
  };

  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    if (files.length > 3) {
      toast.error('Maximum 3 images allowed');
      return;
    }
    // Handle image upload logic here
    setNewReview(prev => ({
      ...prev,
      images: files
    }));
  };

  const handleSubmitReview = async () => {
    if (!user) {
      toast.error('Please log in and select a product to submit a review.');
      return;
    }

    if (newReview.rating === 0 || newReview.comment.trim() === '') {
      toast.error('Please provide both a rating and a comment.');
      return;
    }

    try {
      const reviewData = {
        productId: selectedProduct.id,
        userId: user.uid,
        userName: user.displayName || 'Anonymous',
        userAvatar: user.photoURL,
        rating: newReview.rating,
        comment: newReview.comment.trim(),
        pros: newReview.pros.trim(),
        cons: newReview.cons.trim(),
        installationDate: newReview.installationDate,
        usageDuration: newReview.usageDuration,
        recommendProduct: newReview.recommendProduct,
        images: [], // Will be populated after image upload
        purchaseVerified: false
      };

      await addReview(reviewData);
      toast.success('Review submitted successfully! It will be visible after moderation.');
      setNewReview({
        rating: 0,
        comment: '',
        pros: '',
        cons: '',
        installationDate: '',
        usageDuration: '',
        recommendProduct: true,
        images: []
      });
      setIsWriteReviewOpen(false);
      fetchReviews(); // Refresh reviews list
    } catch (error) {
      console.error('Error submitting review:', error);
      toast.error('Failed to submit review. Please try again.');
    }
  };

  const handleMarkHelpful = async (reviewId) => {
    if (!user) {
      toast.error('Please log in to mark reviews as helpful');
      return;
    }

    if (helpfulReviews.has(reviewId)) {
      toast.error('You have already marked this review as helpful');
      return;
    }

    try {
      await markReviewHelpful(reviewId);
      setHelpfulReviews(prev => new Set([...prev, reviewId]));
      toast.success('Review marked as helpful');
    } catch (error) {
      console.error('Error marking review as helpful:', error);
      toast.error('Failed to mark review as helpful');
    }
  };

  const filteredProducts = products.filter(product =>
    (selectedCategory === 'all' || product.category === selectedCategory) &&
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const productReviews = selectedProduct
    ? reviews.filter(review => review.productId === selectedProduct.id)
    : [];

  const StarRating = ({ rating, size = 'default' }) => {
    const starSize = size === 'large' ? 'w-6 h-6' : 'w-5 h-5';
    return (
      <div className="flex items-center">
        <div className="flex mr-2">
          {[1, 2, 3, 4, 5].map((star) => (
            <span key={star} className={`relative inline-block ${starSize}`}>
              <Star 
                className={`absolute ${starSize} text-gray-300 ${isDarkMode ? 'filter drop-shadow-glow' : ''}`} 
                fill="currentColor"
              />
              <Star 
                className={`absolute ${starSize} text-yellow-400 ${isDarkMode ? 'filter drop-shadow-glow' : ''}`} 
                fill="currentColor"
                style={{
                  clipPath: `inset(0 ${Math.max(0, (star - rating)) * 100}% 0 0)`
                }}
              />
            </span>
          ))}
        </div>
        <span className="text-sm font-semibold">{rating.toFixed(1)}</span>
      </div>
    );
  };

  const ReviewCard = ({ review }) => (
    <div className={`mb-6 p-6 rounded-lg ${isDarkMode ? 'bg-blue-800' : 'bg-white'} shadow-md`}>
      <div className="flex items-start justify-between mb-4">
        <div>
          <div className="flex items-center mb-2">
            {review.userAvatar && (
              <img 
                src={review.userAvatar} 
                alt={review.userName} 
                className="w-10 h-10 rounded-full mr-3"
              />
            )}
            <div>
              <div className="font-semibold">{review.userName}</div>
              <div className="text-sm text-gray-500">
                {new Date(review.createdAt).toLocaleDateString()}
              </div>
            </div>
          </div>
          <StarRating rating={review.rating} />
        </div>
        <div className="flex items-center">
          {review.purchaseVerified && (
            <span className="flex items-center text-green-500 text-sm mr-4">
              <Check className="w-4 h-4 mr-1" />
              Verified Purchase
            </span>
          )}
          {review.status === 'pending' && (
            <span className="flex items-center text-yellow-500 text-sm">
              <Clock className="w-4 h-4 mr-1" />
              Pending Review
            </span>
          )}
        </div>
      </div>

      <div className="mb-4">
        <p className="mb-4">{review.comment}</p>
        {(review.pros || review.cons) && (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            {review.pros && (
              <div>
                <h4 className="font-semibold text-green-500 mb-2">Pros</h4>
                <p>{review.pros}</p>
              </div>
            )}
            {review.cons && (
              <div>
                <h4 className="font-semibold text-red-500 mb-2">Cons</h4>
                <p>{review.cons}</p>
              </div>
            )}
          </div>
        )}
        {review.images?.length > 0 && (
          <div className="flex gap-2 mt-4">
            {review.images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Review image ${index + 1}`}
                className="w-24 h-24 object-cover rounded-lg cursor-pointer"
                onClick={() => {/* Implement image preview */}}
              />
            ))}
          </div>
        )}
      </div>

      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <button
            onClick={() => handleMarkHelpful(review.id)}
            disabled={helpfulReviews.has(review.id)}
            className={`flex items-center text-sm ${
              helpfulReviews.has(review.id)
                ? 'text-gray-500 cursor-not-allowed'
                : isDarkMode
                ? 'text-blue-300 hover:text-blue-200'
                : 'text-blue-600 hover:text-blue-700'
            }`}
          >
            <ThumbsUp className="w-4 h-4 mr-1" />
            Helpful ({review.helpful || 0})
          </button>
        </div>
        {review.recommendProduct && (
          <span className="text-sm text-green-500 flex items-center">
            <Check className="w-4 h-4 mr-1" />
            Would recommend
          </span>
        )}
      </div>
    </div>
  );

  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "Product",
    "name": "Solar Products Reviews | Solar Insight",
    "description": "Authentic customer reviews of solar energy products and solutions",
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": topProduct?.avgRating || "4.8",
      "reviewCount": topProduct?.reviewCount || "150",
      "bestRating": "5",
      "worstRating": "1"
    },
    "review": reviews?.map(review => ({
      "@type": "Review",
      "reviewRating": {
        "@type": "Rating",
        "ratingValue": review.rating
      },
      "author": {
        "@type": "Person",
        "name": review.userName
      },
      "reviewBody": review.comment
    }))
  };

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-blue-900 text-blue-100' : 'bg-amber-50 text-amber-900'}`}>
      <Helmet>
        <title>Solar Product Reviews | Customer Feedback | Solar Insight</title>
        <meta name="description" content="Read authentic customer reviews of solar panels, batteries, and complete solar solutions. Make informed decisions with real user experiences." />
        <meta name="keywords" content="solar reviews, customer feedback, solar panel reviews, solar product ratings, South Africa solar" />
        <script type="application/ld+json">
          {JSON.stringify(jsonLd)}
        </script>
      </Helmet>

      <main className="container mx-auto px-4 py-8">
        <h1 className={`text-3xl md:text-4xl font-bold mb-6 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>
          Solar Product Reviews
        </h1>

        {/* Top Product of the Month Section */}
        {topProduct && (
          <div className={`mb-6 p-4 md:p-6 rounded-lg ${isDarkMode ? 'bg-blue-800' : 'bg-white'} shadow-lg`}>
            <div className="flex items-center mb-3">
              <Award className={`w-6 h-6 md:w-8 md:h-8 mr-2 ${isDarkMode ? 'text-yellow-400' : 'text-amber-500'}`} />
              <h2 className={`text-xl md:text-2xl font-bold ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>
                Top Rated Product
              </h2>
            </div>
            <div className="flex flex-col md:flex-row items-start md:items-center justify-between">
              <div>
                <h3 className="text-lg md:text-xl font-semibold mb-2">{topProduct.name}</h3>
                <p className="text-sm mb-2">{categories.find(c => c.id === topProduct.category).name}</p>
                <div className="flex items-center">
                  <StarRating rating={topProduct.avgRating} size="large" />
                  <span className="ml-2">({topProduct.reviewCount} reviews)</span>
                </div>
              </div>
              <button 
                onClick={() => handleProductSelect(topProduct)}
                className={`mt-4 md:mt-0 px-4 md:px-6 py-2 rounded-lg ${
                  isDarkMode 
                    ? 'bg-yellow-400 text-blue-900 hover:bg-yellow-300' 
                    : 'bg-amber-500 hover:bg-amber-600 text-white'
                } transition-colors duration-200`}
              >
                View Details
              </button>
            </div>
          </div>
        )}

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-6">
          <div className="md:col-span-1 order-2 md:order-1">
            <div className="md:sticky md:top-4">
              <div className="mb-4 relative">
                <input
                  type="text"
                  placeholder="Search products..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className={`w-full pl-10 pr-4 py-2 rounded-lg ${isDarkMode ? 'bg-blue-800 text-blue-100' : 'bg-white text-amber-900'} focus:ring-2 focus:ring-yellow-400`}
                />
                <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
              </div>
              <div className="mb-4">
                <label className="block mb-2 font-semibold">Filter by Category</label>
                <div className="flex flex-wrap">
                  {categories.map(category => (
                    <button
                      key={category.id}
                      onClick={() => setSelectedCategory(category.id)}
                      className={`mr-2 mb-2 px-3 py-1 rounded-full text-sm ${
                        selectedCategory === category.id
                          ? isDarkMode ? 'bg-yellow-400 text-blue-900' : 'bg-amber-500 text-white'
                          : isDarkMode ? 'bg-blue-700 text-blue-100' : 'bg-amber-200 text-amber-800'
                      } transition-colors duration-200`}
                    >
                      {category.name}
                    </button>
                  ))}
                </div>
              </div>
              <ul className="space-y-2 max-h-[calc(100vh-20rem)] overflow-y-auto">
                {filteredProducts.map(product => (
                  <li key={product.id}>
                    <button
                      onClick={() => handleProductSelect(product)}
                      className={`w-full text-left px-3 md:px-4 py-2 rounded-lg transition-colors duration-200 ${
                        selectedProduct?.id === product.id
                          ? isDarkMode ? 'bg-blue-600' : 'bg-amber-200'
                          : isDarkMode ? 'hover:bg-blue-700' : 'hover:bg-amber-100'
                      }`}
                    >
                      <div className="flex justify-between items-center">
                        <span className="font-medium text-sm md:text-base">{product.name}</span>
                        <span className="flex items-center text-xs md:text-sm">
                          <StarRating rating={product.avgRating} />
                          <span className="ml-2">({product.reviewCount})</span>
                        </span>
                      </div>
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="md:col-span-2 order-1 md:order-2">
            {selectedProduct && (
              <>
                <div className={`mb-4 md:mb-6 p-4 rounded-lg ${isDarkMode ? 'bg-blue-800' : 'bg-white'} shadow-md`}>
                  <h2 className="text-xl md:text-2xl font-bold mb-2">{selectedProduct.name}</h2>
                  <p className="text-sm mb-2">{categories.find(c => c.id === selectedProduct.category).name}</p>
                  <div className="flex flex-col md:flex-row items-start md:items-center justify-between gap-4">
                    <div className="flex items-center">
                      <span className="text-base md:text-lg font-semibold mr-2">Average Rating:</span>
                      <StarRating rating={selectedProduct.avgRating} size="large" />
                      <span className="ml-2">({selectedProduct.reviewCount} reviews)</span>
                    </div>
                    <button 
                      onClick={handleWriteReview}
                      className={`w-full md:w-auto px-4 md:px-6 py-2 rounded-lg ${isDarkMode ? 'bg-yellow-400 text-blue-900 hover:bg-yellow-300' : 'bg-amber-500 hover:bg-amber-600 text-white'} transition-colors duration-200`}
                    >
                      Write a Review
                    </button>
                  </div>
                </div>

                {isLoading ? (
                  <div className="text-center py-8">Loading reviews...</div>
                ) : productReviews.length > 0 ? (
                  productReviews.map(review => (
                    <ReviewCard key={review.id} review={review} />
                  ))
                ) : (
                  <div className="text-center py-8">
                    No reviews yet. Be the first to review this product!
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </main>

      {isWriteReviewOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-start justify-center p-4 z-50 overflow-y-auto">
          <div className={`relative my-8 p-6 rounded-lg ${isDarkMode ? 'bg-blue-800' : 'bg-white'} w-full max-w-2xl`}>
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-2xl font-bold">Write a Review</h2>
              <button 
                onClick={() => setIsWriteReviewOpen(false)} 
                className={`${isDarkMode ? 'text-blue-200 hover:text-blue-100' : 'text-amber-700 hover:text-amber-800'}`}
              >
                <X />
              </button>
            </div>

            <div className="mb-6">
              <label className="block mb-2 font-semibold">Rating</label>
              <div className="flex">
                {[1, 2, 3, 4, 5].map((star) => (
                  <button
                    key={star}
                    onClick={() => setNewReview({ ...newReview, rating: star })}
                    className={`mr-1 ${star <= newReview.rating ? 'text-yellow-400' : isDarkMode ? 'text-blue-300' : 'text-amber-300'}`}
                  >
                    <Star fill={star <= newReview.rating ? 'currentColor' : 'none'} className="w-8 h-8" />
                  </button>
                ))}
              </div>
            </div>

            <div className="mb-4">
              <label className="block mb-2 font-semibold">Overall Review</label>
              <textarea
                value={newReview.comment}
                onChange={(e) => setNewReview({ ...newReview, comment: e.target.value })}
                className={`w-full p-2 rounded-lg ${isDarkMode ? 'bg-blue-700 text-blue-100' : 'bg-amber-100 text-amber-900'} focus:ring-2 focus:ring-yellow-400`}
                rows="4"
                placeholder="Share your experience with this product..."
              ></textarea>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
              <div>
                <label className="block mb-2 font-semibold">Pros</label>
                <textarea
                  value={newReview.pros}
                  onChange={(e) => setNewReview({ ...newReview, pros: e.target.value })}
                  className={`w-full p-2 rounded-lg ${isDarkMode ? 'bg-blue-700 text-blue-100' : 'bg-amber-100 text-amber-900'} focus:ring-2 focus:ring-yellow-400`}
                  rows="3"
                  placeholder="What did you like?"
                ></textarea>
              </div>
              <div>
                <label className="block mb-2 font-semibold">Cons</label>
                <textarea
                  value={newReview.cons}
                  onChange={(e) => setNewReview({ ...newReview, cons: e.target.value })}
                  className={`w-full p-2 rounded-lg ${isDarkMode ? 'bg-blue-700 text-blue-100' : 'bg-amber-100 text-amber-900'} focus:ring-2 focus:ring-yellow-400`}
                  rows="3"
                  placeholder="What could be improved?"
                ></textarea>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
              <div>
                <label className="block mb-2 font-semibold">Installation Date</label>
                <input
                  type="date"
                  value={newReview.installationDate}
                  onChange={(e) => setNewReview({ ...newReview, installationDate: e.target.value })}
                  className={`w-full p-2 rounded-lg ${isDarkMode ? 'bg-blue-700 text-blue-100' : 'bg-amber-100 text-amber-900'} focus:ring-2 focus:ring-yellow-400`}
                />
              </div>
              <div>
                <label className="block mb-2 font-semibold">Usage Duration</label>
                <select
                  value={newReview.usageDuration}
                  onChange={(e) => setNewReview({ ...newReview, usageDuration: e.target.value })}
                  className={`w-full p-2 rounded-lg ${isDarkMode ? 'bg-blue-700 text-blue-100' : 'bg-amber-100 text-amber-900'} focus:ring-2 focus:ring-yellow-400`}
                >
                  <option value="">Select duration</option>
                  <option value="less-than-month">Less than a month</option>
                  <option value="1-3-months">1-3 months</option>
                  <option value="3-6-months">3-6 months</option>
                  <option value="6-12-months">6-12 months</option>
                  <option value="over-1-year">Over 1 year</option>
                </select>
              </div>
            </div>

            <div className="mb-4">
              <label className="block mb-2 font-semibold">Add Photos (Max 3)</label>
              <input
                type="file"
                accept="image/*"
                multiple
                onChange={handleImageUpload}
                className="w-full"
              />
              <p className="text-sm text-gray-500 mt-1">
                Supported formats: JPG, PNG (max 5MB each)
              </p>
            </div>

            <div className="mb-6">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={newReview.recommendProduct}
                  onChange={(e) => setNewReview({ ...newReview, recommendProduct: e.target.checked })}
                  className="mr-2"
                />
                <span>I would recommend this product</span>
              </label>
            </div>

            <div className="flex justify-end">
              <button
                onClick={handleSubmitReview}
                className={`px-6 py-2 rounded-lg ${isDarkMode ? 'bg-yellow-400 text-blue-900 hover:bg-yellow-300' : 'bg-amber-500 hover:bg-amber-600 text-white'} transition-colors duration-200`}
              >
                Submit Review
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReviewsPage;