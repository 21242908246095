import React from 'react';
import { useTheme } from '../contexts/ThemeContext';
import { CheckCircle } from 'lucide-react';
import { Link } from 'react-router-dom';

const ThankYouPage = () => {
  const { isDarkMode } = useTheme();

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-blue-900 text-blue-100' : 'bg-amber-50 text-amber-900'}`}>
      <main className="container mx-auto px-4 py-16 text-center">
        <div className={`max-w-lg mx-auto p-8 rounded-lg ${isDarkMode ? 'bg-blue-800' : 'bg-white'} shadow-lg`}>
          <CheckCircle className={`w-16 h-16 mx-auto mb-6 ${isDarkMode ? 'text-green-400' : 'text-green-500'}`} />
          <h1 className={`text-3xl font-bold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>
            Thank You for Contacting Us!
          </h1>
          <p className="text-lg mb-8">
            We've received your message and will get back to you within 24 hours.
          </p>
          <div className="space-y-4">
            <p className="font-medium">In the meantime, you can:</p>
            <div className="flex flex-col space-y-2">
              <Link 
                to="/calculators/savings" 
                className={`px-4 py-2 rounded-md ${
                  isDarkMode 
                    ? 'bg-blue-700 hover:bg-blue-600' 
                    : 'bg-amber-500 hover:bg-amber-600 text-white'
                }`}
              >
                Try Our Solar Savings Calculator
              </Link>
              <Link 
                to="/product/all" 
                className={`px-4 py-2 rounded-md ${
                  isDarkMode 
                    ? 'bg-blue-700 hover:bg-blue-600' 
                    : 'bg-amber-500 hover:bg-amber-600 text-white'
                }`}
              >
                Browse Our Products
              </Link>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ThankYouPage; 