import { useState } from 'react';
import { createUserWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { auth, googleProvider } from '../../firebase';
import styles from './Auth.module.css';

export default function SignUp() {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

  const handleSignUp = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      // You can add additional user info like fullName to Firestore here
    } catch (error) {
      setError(error.message);
    }
  };

  const signUpWithGoogle = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
    } catch (error) {
      setError('Could not sign up with Google');
    }
  };

  return (
    <div className={styles.authContainer}>
      <h1>Create your account</h1>
      
      <form onSubmit={handleSignUp} className={styles.form}>
        <div className={styles.inputGroup}>
          <label>Full Name</label>
          <div className={styles.inputWrapper}>
            <i className={styles.userIcon} />
            <input
              type="text"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              placeholder="John Doe"
              required
            />
          </div>
        </div>

        <div className={styles.inputGroup}>
          <label>Email address</label>
          <div className={styles.inputWrapper}>
            <i className={styles.emailIcon} />
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="you@example.com"
              required
            />
          </div>
        </div>

        <div className={styles.inputGroup}>
          <label>Password</label>
          <div className={styles.inputWrapper}>
            <i className={styles.lockIcon} />
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
        </div>

        <div className={styles.inputGroup}>
          <label>Confirm Password</label>
          <div className={styles.inputWrapper}>
            <i className={styles.lockIcon} />
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
        </div>

        {error && <div className={styles.error}>{error}</div>}
        
        <button type="submit" className={styles.submitButton}>
          Sign up
        </button>
      </form>

      <div className={styles.divider}>Or</div>

      <button onClick={signUpWithGoogle} className={styles.googleButton}>
        <img src="/google-icon.svg" alt="Google" />
        Sign up with Google
      </button>

      <p className={styles.switchAuth}>
        Already have an account? <a href="/signin">Sign in</a>
      </p>
    </div>
  );
} 