import React, { useState, useEffect, useRef } from 'react';
import { LightBulbIcon, MagnifyingGlassIcon, ExclamationCircleIcon, ClockIcon } from '@heroicons/react/24/outline';
import { useTheme } from '../../contexts/ThemeContext';
import { Helmet } from 'react-helmet';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import { collection, addDoc, query, orderBy, limit, onSnapshot, updateDoc, doc, serverTimestamp } from 'firebase/firestore';
import { logEvent } from 'firebase/analytics';
import { db, analytics } from '../../firebase';
import loadSheddingService from '../../services/loadSheddingService';

// Define libraries array outside component to prevent reloading
const libraries = ['places'];

// Map configuration
const mapOptions = {
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  libraries,
  version: "weekly"
};

// Track analytics event helper function with better error handling
const trackEvent = (eventName, eventParams = {}) => {
  if (process.env.NODE_ENV === 'production' && analytics) {
    try {
      logEvent(analytics, eventName, {
        ...eventParams,
        timestamp: new Date().toISOString(),
        environment: process.env.NODE_ENV
      });
    } catch (error) {
      console.debug('Analytics event not tracked:', eventName, error);
    }
  }
};

// Function to format relative time
const getRelativeTime = (timestamp) => {
  if (!timestamp) return 'Just now';
  
  const now = new Date();
  const date = timestamp.toDate();
  const minutes = Math.floor((now - date) / 60000);
  
  if (minutes < 1) return 'Just now';
  if (minutes < 60) return `${minutes}m ago`;
  
  const hours = Math.floor(minutes / 60);
  if (hours < 24) return `${hours}h ago`;
  
  const days = Math.floor(hours / 24);
  return `${days}d ago`;
};

const containerStyle = {
  width: '100%',
  height: '400px'
};

// Dark mode map styles
const darkMapStyle = [
  { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
  { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
  { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
  {
    featureType: "administrative.locality",
    elementType: "labels.text.fill",
    stylers: [{ color: "#d59563" }],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [{ color: "#38414e" }],
  },
  {
    featureType: "road",
    elementType: "geometry.stroke",
    stylers: [{ color: "#212a37" }],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [{ color: "#9ca5b3" }],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [{ color: "#17263c" }],
  }
];

const LoadSheddingPage = () => {
  const { isDarkMode } = useTheme();
  const [currentStage, setCurrentStage] = useState(0);
  const [nextOutage, setNextOutage] = useState('Loading...');
  const [userLocation, setUserLocation] = useState({ lat: -26.2041, lng: 28.0473 });
  const [locationError, setLocationError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [reportArea, setReportArea] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedArea, setSelectedArea] = useState(null);
  const [schedule, setSchedule] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [reports, setReports] = useState([]);
  const [reportType, setReportType] = useState('outage');
  const [reportNote, setReportNote] = useState('');
  const searchInputRef = useRef(null);
  const [placesService, setPlacesService] = useState(null);
  const [autocompleteService, setAutocompleteService] = useState(null);

  // Use the static mapOptions configuration
  const { isLoaded, loadError } = useLoadScript(mapOptions);

  // Track page view on component mount
  useEffect(() => {
    trackEvent('page_view', {
      page_title: 'Load Shedding Information',
      page_location: window.location.href
    });
  }, []);

  // Get user's location
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude
          });
        },
        (error) => {
          console.error("Error getting location:", error);
          setLocationError("Unable to get your location. Using default location (Johannesburg).");
        }
      );
    }
  }, []);

  // Fetch current load shedding status using the new service
  useEffect(() => {
    const fetchStatus = async () => {
      try {
        setError(null);
        const statusData = await loadSheddingService.getStatus();
        const stage = statusData.status.eskom.stage;
        setCurrentStage(stage);
        
        if (stage > 0) {
          setNextOutage('Check schedule for next outage times');
        } else {
          setNextOutage('No load shedding currently scheduled');
        }

        // Track status update
        trackEvent('load_shedding_status', {
          stage: stage,
          status: stage > 0 ? 'active' : 'inactive'
        });
      } catch (error) {
        console.error('Error fetching load shedding status:', error);
        setError('Failed to fetch current load shedding status. Please try again later.');
      }
    };

    fetchStatus();
    const interval = setInterval(fetchStatus, 300000); // 5 minutes
    return () => clearInterval(interval);
  }, []);

  // Initialize Google Places services
  useEffect(() => {
    if (isLoaded && window.google) {
      const map = new window.google.maps.Map(document.createElement('div'));
      setPlacesService(new window.google.maps.places.PlacesService(map));
      setAutocompleteService(new window.google.maps.places.AutocompleteService());
    }
  }, [isLoaded]);

  // Initialize Google Places Autocomplete
  useEffect(() => {
    if (isLoaded && window.google && searchInputRef.current) {
      const autocomplete = new window.google.maps.places.Autocomplete(searchInputRef.current, {
        componentRestrictions: { country: 'za' },
        types: ['(cities)', 'sublocality', 'locality', 'neighborhood'],
        fields: ['place_id', 'geometry', 'name', 'formatted_address']
      });

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        if (place.geometry) {
          const areaWithLocation = {
            id: place.place_id,
            name: place.name,
            fullName: place.formatted_address,
            location: {
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng()
            }
          };
          
          handleAreaSelect(areaWithLocation);
        }
      });
    }
  }, [isLoaded]);

  // Initialize report form autocomplete
  const reportInputRef = useRef(null);

  useEffect(() => {
    if (isLoaded && window.google && reportInputRef.current) {
      const autocomplete = new window.google.maps.places.Autocomplete(reportInputRef.current, {
        componentRestrictions: { country: 'za' },
        types: ['(cities)', 'sublocality', 'locality', 'neighborhood'],
        fields: ['place_id', 'geometry', 'name', 'formatted_address']
      });

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        if (place.geometry) {
          setReportArea(place.name);
          setUserLocation({
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng()
          });
        }
      });
    }
  }, [isLoaded]);

  // Handle search input change with immediate feedback
  const handleSearchInputChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
    if (!value.trim()) {
      setSearchResults([]);
    }
  };

  // Handle area selection
  const handleAreaSelect = async (area) => {
    try {
      setLoading(true);
      setSelectedArea(area);
      setSearchQuery(area.name);
      setReportArea(area.name);
      
      // Try to get load shedding schedule for the area
      try {
        const scheduleData = await loadSheddingService.getAreaSchedule(area.id);
        setSchedule(scheduleData.schedule.days || []);
        
        if (scheduleData.schedule.days.length > 0) {
          setNextOutage(scheduleData.schedule.days[0].date);
        }
      } catch (scheduleError) {
        console.error('Error fetching schedule:', scheduleError);
        setSchedule([]);
        setNextOutage('Schedule not available for this area');
      }

      trackEvent('select_area', {
        area_id: area.id,
        area_name: area.name,
        has_coordinates: true
      });
    } catch (error) {
      console.error('Error handling area selection:', error);
      setError('Failed to get area details. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Load reports from Firestore
  useEffect(() => {
    const q = query(collection(db, 'reports'), orderBy('timestamp', 'desc'), limit(50));
    
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const newReports = [];
      snapshot.forEach((doc) => {
        newReports.push({ id: doc.id, ...doc.data() });
      });
      setReports(newReports);
    }, (error) => {
      console.error('Error loading reports:', error);
      setError('Failed to load reports. Please try again later.');
    });

    return () => unsubscribe();
  }, []);

  // Handle report submission
  const handleReportSubmit = async (e) => {
    e.preventDefault();
    
    if (!reportArea.trim()) {
      setError('Please enter an area for your report');
      return;
    }

    try {
      setLoading(true);
      const docRef = await addDoc(collection(db, 'reports'), {
        area: reportArea,
        type: reportType,
        note: reportNote.trim(),
        timestamp: serverTimestamp(),
        location: userLocation,
        votes: 0
      });

      // Track report submission
      trackEvent('submit_report', {
        report_type: reportType,
        area: reportArea,
        has_note: !!reportNote.trim()
      });

      setReportArea('');
      setReportNote('');
      setError(null);
    } catch (error) {
      console.error('Error submitting report:', error);
      setError('Failed to submit report. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Handle voting
  const handleVote = async (reportId) => {
    try {
      const reportRef = doc(db, 'reports', reportId);
      const report = reports.find(r => r.id === reportId);
      const newVotes = report.votes + 1;
      
      await updateDoc(reportRef, {
        votes: newVotes
      });

      // Track vote
      trackEvent('vote_report', {
        report_type: report.type,
        area: report.area,
        votes: newVotes
      });
    } catch (error) {
      console.error('Error updating votes:', error);
      setError('Failed to update vote. Please try again.');
    }
  };

  // Handle area search for report form
  const handleAreaSearch = async (searchText) => {
    if (!searchText.trim()) {
      setSearchResults([]);
      return;
    }
    
    try {
      setLoading(true);
      const results = await loadSheddingService.getAreaSearch(searchText);
      setSearchResults(results.areas || []);
    } catch (error) {
      console.error('Error searching areas:', error);
      setError('Failed to search areas. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  if (loadError) {
    return <div className="text-center p-4">Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div className="text-center p-4">Loading maps...</div>;
  }

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-blue-900 text-blue-100' : 'bg-amber-50 text-amber-900'}`}>
      <main className="container mx-auto px-4 py-8">
        <h1 className={`text-4xl font-bold mb-8 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>
          Load Shedding Information
        </h1>
        
        {error && (
          <div className="mb-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded">
            {error}
          </div>
        )}

        <div className={`p-6 rounded-lg ${isDarkMode ? 'bg-blue-800' : 'bg-white'} shadow-lg mb-8`}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <h2 className={`text-2xl font-bold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>
                Current Status
              </h2>
              <div className="space-y-4">
                <div className="flex justify-between items-center">
                  <span className={`text-lg ${isDarkMode ? 'text-blue-300' : 'text-amber-700'}`}>Current Stage:</span>
                  <span className={`text-3xl font-bold ${currentStage === 0 ? 'text-green-500' : 'text-red-500'}`}>
                    {currentStage === 0 ? 'No Load Shedding' : `Stage ${currentStage}`}
                  </span>
                </div>
                <div className="flex justify-between items-center">
                  <span className={`text-lg ${isDarkMode ? 'text-blue-300' : 'text-amber-700'}`}>Next Outage:</span>
                  <span className="text-xl font-bold">{nextOutage}</span>
                </div>
              </div>
            </div>

            <div>
              <h2 className={`text-2xl font-bold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>
                Search Your Location
              </h2>
              <div className="space-y-4">
                <div className="relative">
                  <input
                    type="text"
                    ref={searchInputRef}
                    placeholder="Start typing to search your area..."
                    className={`w-full p-3 rounded-md ${
                      isDarkMode 
                        ? 'bg-blue-700 border-blue-600 text-blue-100' 
                        : 'bg-amber-100 border-amber-300 text-amber-900'
                    } focus:outline-none focus:ring-2 ${
                      isDarkMode ? 'focus:ring-yellow-400' : 'focus:ring-amber-500'
                    }`}
                  />
                  {loading ? (
                    <div className="absolute right-3 top-3.5">
                      <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-gray-400"></div>
                    </div>
                  ) : (
                    <MagnifyingGlassIcon className="h-5 w-5 absolute right-3 top-3.5 text-gray-400" />
                  )}
                  
                  {searchResults.length > 0 && (
                    <div 
                      className={`absolute z-10 w-full mt-1 rounded-md shadow-lg ${
                        isDarkMode ? 'bg-blue-700' : 'bg-white'
                      } max-h-60 overflow-y-auto`}
                    >
                      {searchResults.map((result) => (
                          <button
                            key={result.id}
                            type="button"
                            onClick={() => handleAreaSelect(result)}
                            className={`w-full text-left px-4 py-3 hover:${
                              isDarkMode ? 'bg-blue-600' : 'bg-amber-50'
                          } transition-colors duration-150`}
                        >
                          <div className="flex items-start">
                            <MagnifyingGlassIcon className="h-5 w-5 text-gray-400 flex-shrink-0 mt-0.5 mr-2" />
                            <div>
                              <div className="font-medium">{result.name}</div>
                              <div className="text-sm text-gray-500 dark:text-gray-300">
                                {result.fullName}
                              </div>
                            </div>
                          </div>
                          </button>
                      ))}
                    </div>
                  )}
                  
                  {searchQuery.trim().length > 0 && searchResults.length === 0 && !loading && (
                    <div className={`absolute z-10 w-full mt-1 rounded-md shadow-lg ${
                      isDarkMode ? 'bg-blue-700' : 'bg-white'
                    } p-3 text-center text-sm`}>
                      No areas found matching "{searchQuery}"
                    </div>
                  )}
                </div>

                {schedule.length > 0 && (
                  <div className="mt-4">
                    <h3 className={`text-lg font-semibold mb-2 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>
                      Load Shedding Schedule
                    </h3>
                    <div className="max-h-48 overflow-y-auto space-y-2">
                      {schedule.map((day, index) => (
                        <div
                          key={index}
                          className={`p-3 rounded-md ${isDarkMode ? 'bg-blue-700' : 'bg-amber-100'}`}
                        >
                          <p className="font-semibold">{day.date}</p>
                          {day.stages.map((stage, stageIndex) => (
                            <p key={stageIndex} className="text-sm">
                              Stage {stage.stage}: {stage.start} - {stage.end}
                            </p>
                          ))}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className={`p-6 rounded-lg ${isDarkMode ? 'bg-blue-800' : 'bg-white'} shadow-lg mb-8`}>
          <h2 className={`text-2xl font-bold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>Area Map</h2>
          {locationError && (
            <p className="text-red-500 mb-2">{locationError}</p>
          )}
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={selectedArea?.location || userLocation}
            zoom={12}
            options={{
              styles: isDarkMode ? darkMapStyle : [],
              zoomControl: true,
              mapTypeControl: false,
              scaleControl: true,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: true
            }}
          >
            {selectedArea?.location && (
              <Marker 
                position={selectedArea.location}
                title={selectedArea.name}
              />
            )}
          </GoogleMap>
        </div>

        <div className={`p-6 rounded-lg ${isDarkMode ? 'bg-blue-800' : 'bg-white'} shadow-lg mb-8`}>
          <h2 className={`text-2xl font-bold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>
            About Load Shedding
          </h2>
          <div className="space-y-4">
            <p>Load shedding is a controlled process that responds to unplanned events to protect the electricity power system from a total blackout.</p>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className={`p-4 rounded-md ${isDarkMode ? 'bg-blue-700' : 'bg-amber-100'}`}>
                <h3 className="font-bold mb-2">Load Shedding Stages</h3>
                <ul className="list-disc list-inside space-y-1">
                  <li>Stage 1: 1000MW needs to be shed</li>
                  <li>Stage 2: 2000MW needs to be shed</li>
                  <li>Stage 3: 3000MW needs to be shed</li>
                  <li>Stage 4: 4000MW needs to be shed</li>
                  <li>Stage 5: 5000MW needs to be shed</li>
                  <li>Stage 6: 6000MW needs to be shed</li>
                  <li>Stage 7: 7000MW needs to be shed</li>
                  <li>Stage 8: 8000MW needs to be shed</li>
                </ul>
              </div>
              
              <div className={`p-4 rounded-md ${isDarkMode ? 'bg-blue-700' : 'bg-amber-100'}`}>
                <h3 className="font-bold mb-2">What to Expect</h3>
                <ul className="list-disc list-inside space-y-1">
                  <li>Stage 1-2: 2 hour outages up to 2 times per day</li>
                  <li>Stage 3-4: 2.5 hour outages up to 3 times per day</li>
                  <li>Stage 5-6: 4 hour outages up to 4 times per day</li>
                  <li>Stage 7-8: 4+ hour outages multiple times per day</li>
                </ul>
              </div>
            </div>

            <div className={`p-4 rounded-md ${isDarkMode ? 'bg-blue-700' : 'bg-amber-100'} mt-4`}>
              <h3 className="font-bold mb-2">Tips During Load Shedding</h3>
              <ul className="list-disc list-inside space-y-1">
                <li>Keep your cell phone charged when power is available</li>
                <li>Fill your vehicle's fuel tank - petrol stations cannot pump fuel during power outages</li>
                <li>Keep some cash on hand - ATMs cannot operate without electricity</li>
                <li>Stock up on battery-powered lights and ensure batteries are fresh</li>
                <li>If you have a generator, ensure it's well-maintained and you have sufficient fuel</li>
                <li>Backup your computer and save your work frequently</li>
              </ul>
            </div>
          </div>
        </div>

        <div className={`p-6 rounded-lg ${isDarkMode ? 'bg-blue-800' : 'bg-white'} shadow-lg mb-8`}>
          <h2 className={`text-2xl font-bold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>
            Report Power Status
          </h2>
          <div className="mb-6">
            <p className="text-sm mb-4">
              Help your community by reporting power outages or restorations in your area. 
              Your reports help others stay informed about the current situation.
            </p>
          </div>
          
          <form onSubmit={handleReportSubmit} className="space-y-4">
            <div className="relative">
              <label className="block mb-2">Your Area</label>
              <input
                type="text"
                ref={reportInputRef}
                defaultValue={reportArea}
                placeholder="Enter your area name..."
                className={`w-full p-2 rounded-md ${
                  isDarkMode 
                    ? 'bg-blue-700 border-blue-600 text-blue-100' 
                    : 'bg-amber-100 border-amber-300 text-amber-900'
                }`}
              />
            </div>

            <div>
              <label className="block mb-2">Status Type</label>
              <div className="flex gap-4">
                <label className="flex items-center">
                  <input
                    type="radio"
                    value="outage"
                    checked={reportType === 'outage'}
                    onChange={(e) => setReportType(e.target.value)}
                    className="mr-2"
                  />
                  <span className="flex items-center">
                    <LightBulbIcon className="h-5 w-5 mr-1 text-red-500" />
                    Power Outage
                  </span>
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    value="restoration"
                    checked={reportType === 'restoration'}
                    onChange={(e) => setReportType(e.target.value)}
                    className="mr-2"
                  />
                  <span className="flex items-center">
                    <LightBulbIcon className="h-5 w-5 mr-1 text-green-500" />
                    Power Restored
                  </span>
                </label>
              </div>
            </div>

            <div>
              <label className="block mb-2">Additional Details (Optional)</label>
              <textarea
                value={reportNote}
                onChange={(e) => setReportNote(e.target.value)}
                placeholder="Any additional information about the power status..."
                className={`w-full p-2 rounded-md ${
                  isDarkMode 
                    ? 'bg-blue-700 border-blue-600 text-blue-100' 
                    : 'bg-amber-100 border-amber-300 text-amber-900'
                }`}
                rows="3"
              />
            </div>

            <button
              type="submit"
              disabled={loading}
              className={`w-full p-2 rounded-md ${
                isDarkMode 
                  ? 'bg-yellow-400 text-blue-900 hover:bg-yellow-300' 
                  : 'bg-amber-500 text-white hover:bg-amber-600'
              } transition-colors duration-200 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              {loading ? 'Submitting...' : 'Submit Report'}
            </button>
          </form>
        </div>
        
        <div className={`p-6 rounded-lg ${isDarkMode ? 'bg-blue-800' : 'bg-white'} shadow-lg mb-8`}>
          <h2 className={`text-2xl font-bold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>
            Community Reports
          </h2>
          <div className="space-y-4">
            {reports.length === 0 ? (
              <p className="text-center italic">No reports yet. Be the first to report!</p>
            ) : (
              reports.map(report => (
                <div
                  key={report.id}
                  className={`p-4 rounded-md ${isDarkMode ? 'bg-blue-700' : 'bg-amber-100'} relative`}
                >
                  <div className="flex justify-between items-start">
                    <div>
                      <h3 className="font-semibold flex items-center">
                        <LightBulbIcon 
                          className={`h-5 w-5 mr-2 ${
                            report.type === 'outage' ? 'text-red-500' : 'text-green-500'
                          }`}
                        />
                        {report.area}
                      </h3>
                      <p className={`text-sm ${
                        report.type === 'outage' 
                          ? 'text-red-500 dark:text-red-400' 
                          : 'text-green-500 dark:text-green-400'
                      }`}>
                        {report.type === 'outage' ? 'Power Outage' : 'Power Restored'}
                      </p>
                      {report.note && (
                        <p className="mt-2 text-sm">{report.note}</p>
                      )}
                    </div>
                    <div className="text-right">
                      <button
                        onClick={() => handleVote(report.id)}
                        className={`flex items-center space-x-1 text-sm ${
                          isDarkMode ? 'text-blue-300 hover:text-blue-200' : 'text-amber-700 hover:text-amber-600'
                        }`}
                      >
                        <span>👍</span>
                        <span>{report.votes}</span>
                      </button>
                      <div className="text-sm mt-2 flex items-center justify-end space-x-1">
                        <ClockIcon className="h-4 w-4" />
                        <span>{report.timestamp ? getRelativeTime(report.timestamp) : 'Just now'}</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </main>
    </div>
  );
};

export default LoadSheddingPage;