import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';
import { signOut } from 'firebase/auth';
import { auth } from '../../firebase';
import styles from './Navbar.module.css';

export default function Navbar() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate('/signin');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  if (!user) return null;

  return (
    <nav className={styles.navbar}>
      <div className={styles.logo}>
        Solar Insight
      </div>
      <div className={styles.links}>
        <Link to="/loadshedding">Load Shedding</Link>
        <Link to="/battery">Battery</Link>
        <Link to="/solar-tilt">Solar Tilt</Link>
        <Link to="/savings-calculator">Savings Calculator</Link>
      </div>
      <div className={styles.auth}>
        <span>{user.email}</span>
        <button onClick={handleSignOut}>Sign Out</button>
      </div>
    </nav>
  );
} 