import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { Cpu } from 'lucide-react';
import SEO from '../SEO';

const ChargeControllerPage = () => {
  const { isDarkMode } = useTheme();

  const types = [
    { name: 'PWM (Pulse Width Modulation)', description: 'Cost-effective option for smaller systems' },
    { name: 'MPPT (Maximum Power Point Tracking)', description: 'More efficient, ideal for larger systems or varying conditions' },
  ];

  const features = [
    'Charging efficiency',
    'Voltage and current ratings',
    'Temperature compensation',
    'Display and monitoring capabilities',
    'Battery type compatibility',
  ];

  const brands = ['Victron Energy', 'Morningstar', 'Epever', 'Outback Power'];

  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "Product",
    "name": "Solar Charge Controllers | Solar Insight",
    "description": "Advanced charge controllers for optimal solar battery charging",
    "category": "Solar Charge Controllers",
    "brand": {
      "@type": "Brand",
      "name": "Solar Insight"
    },
    "offers": {
      "@type": "AggregateOffer",
      "priceCurrency": "ZAR",
      "lowPrice": "1000",
      "highPrice": "8000",
      "offerCount": "12"
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.5",
      "reviewCount": "95"
    }
  };

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-blue-900 text-blue-100' : 'bg-amber-50 text-amber-900'}`}>
      <SEO 
        title="Solar Charge Controllers | Power Management"
        description="Advanced solar charge controllers for optimal battery charging and system protection. Find the right controller for your solar setup."
        keywords="charge controllers, MPPT controllers, PWM controllers, solar power management, battery charging, South Africa"
        canonicalUrl="https://solarinsight.co.za/products/controllers"
        ogType="product"
        jsonLd={jsonLd}
      />
      
      <main className="container mx-auto px-4 py-8">
        <div className="flex items-center mb-8">
          <Cpu className={`w-10 h-10 mr-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-500'}`} />
          <h1 className={`text-4xl font-bold ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>
            Solar Charge Controllers
          </h1>
        </div>

        <p className="mb-8 text-lg">
          Charge controllers regulate the voltage and current from solar panels to batteries, ensuring optimal charging and system longevity.
        </p>

        <section className="mb-8">
          <h2 className={`text-2xl font-semibold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>
            Types of Charge Controllers
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {types.map((type, index) => (
              <div key={index} className={`p-4 rounded-lg ${isDarkMode ? 'bg-blue-800' : 'bg-white'} shadow-md`}>
                <h3 className="text-xl font-semibold mb-2">{type.name}</h3>
                <p>{type.description}</p>
              </div>
            ))}
          </div>
        </section>

        <section className="mb-8">
          <h2 className={`text-2xl font-semibold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>
            Key Features
          </h2>
          <ul className="list-disc list-inside">
            {features.map((feature, index) => (
              <li key={index} className="mb-2">{feature}</li>
            ))}
          </ul>
        </section>

        <section className="mb-8">
          <h2 className={`text-2xl font-semibold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>
            Top Brands in SA
          </h2>
          <div className="flex flex-wrap">
            {brands.map((brand, index) => (
              <span key={index} className={`mr-4 mb-2 px-3 py-1 rounded-full ${isDarkMode ? 'bg-blue-800' : 'bg-amber-200'}`}>
                {brand}
              </span>
            ))}
          </div>
        </section>

        <section>
          <h2 className={`text-2xl font-semibold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>
            Price Range
          </h2>
          <p className="text-lg font-semibold">
            R1,000 - R15,000 (depending on type and capacity)
          </p>
        </section>
      </main>
    </div>
  );
};

export default ChargeControllerPage;