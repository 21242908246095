import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { Sun } from 'lucide-react';
import SEO from '../SEO';

const SolarPanelPage = () => {
  const { isDarkMode } = useTheme();

  const types = [
    { name: 'Monocrystalline Solar Panels', efficiency: '20-22%', bestFor: 'Best for limited roof space in urban areas' },
    { name: 'Polycrystalline Solar Panels', efficiency: '15-17%', bestFor: 'More affordable option, popular in residential installations' },
    { name: 'Thin-Film Solar Panels', efficiency: '10-12%', bestFor: 'Flexible, good for unconventional installations' },
  ];

  const features = [
    'Efficiency rating',
    'Temperature coefficient (important for SA\'s hot climate)',
    'Warranty (typically 25 years)',
    'Hail resistance (crucial for some SA regions)',
  ];

  const brands = ['Canadian Solar', 'JA Solar', 'Jinko Solar', 'LONGi Solar'];

  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "Product",
    "category": "Solar Panels",
    "name": "Solar Panels | Solar Insight",
    "description": "High-quality solar panels for residential and commercial use in South Africa",
    "offers": {
      "@type": "AggregateOffer",
      "priceCurrency": "ZAR",
      "lowPrice": "1500",
      "highPrice": "5000",
      "offerCount": "10"
    },
    "brand": {
      "@type": "Brand",
      "name": "Solar Insight"
    }
  };

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-blue-900 text-blue-100' : 'bg-amber-50 text-amber-900'}`}>
      <SEO 
        title="Solar Panels | Solar Energy Products"
        description="Explore our range of high-quality solar panels for residential and commercial use. Find the perfect solar panel solution for your energy needs."
        keywords="solar panels, photovoltaic panels, PV modules, solar energy, South Africa, monocrystalline, polycrystalline"
        canonicalUrl="https://solarinsight.co.za/products/solar-panels"
        jsonLd={jsonLd}
      />
      
      <main className="container mx-auto px-4 py-8">
        <div className="flex items-center mb-8">
          <Sun className={`w-10 h-10 mr-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-500'}`} />
          <h1 className={`text-4xl font-bold ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>
            High-Efficiency Solar Panels
          </h1>
        </div>

        <p className="mb-8 text-lg">
          Solar panels are essential for converting sunlight into electricity, crucial for South Africa's sunny climate and frequent load shedding issues.
        </p>

        <section className="mb-8">
          <h2 className={`text-2xl font-semibold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>
            Types of Solar Panels
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {types.map((type, index) => (
              <div key={index} className={`p-4 rounded-lg ${isDarkMode ? 'bg-blue-800' : 'bg-white'} shadow-md`}>
                <h3 className="text-xl font-semibold mb-2">{type.name}</h3>
                <p>Efficiency: {type.efficiency}</p>
                <p>{type.bestFor}</p>
              </div>
            ))}
          </div>
        </section>

        <section className="mb-8">
          <h2 className={`text-2xl font-semibold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>
            Key Features
          </h2>
          <ul className="list-disc list-inside">
            {features.map((feature, index) => (
              <li key={index} className="mb-2">{feature}</li>
            ))}
          </ul>
        </section>

        <section className="mb-8">
          <h2 className={`text-2xl font-semibold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>
            Top Brands Available in SA
          </h2>
          <div className="flex flex-wrap">
            {brands.map((brand, index) => (
              <span key={index} className={`mr-4 mb-2 px-3 py-1 rounded-full ${isDarkMode ? 'bg-blue-800' : 'bg-amber-200'}`}>
                {brand}
              </span>
            ))}
          </div>
        </section>

        <section>
          <h2 className={`text-2xl font-semibold mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>
            Price Range
          </h2>
          <p className="text-lg font-semibold">
            R1,500 - R5,000 per panel (depending on type and efficiency)
          </p>
        </section>
      </main>
    </div>
  );
};

export default SolarPanelPage;