import React, { useState, useEffect } from 'react';
import { AlertTriangle, ArrowUp, RotateCcw, RefreshCw, MapPin, ChevronUp, ChevronDown } from 'lucide-react';
import { useTheme } from '../../contexts/ThemeContext';

const SolarTiltGauge = () => {
  const { isDarkMode } = useTheme();
  
  // State Variables
  const [orientation, setOrientation] = useState({ alpha: 0, beta: 0, gamma: 0, tilt: 0 });
  const [calibration, setCalibration] = useState({ beta: null, gamma: null });
  const [isCalibrating, setIsCalibrating] = useState(false);
  const [calibrationProgress, setCalibrationProgress] = useState(0);
  const [deviceOrientation, setDeviceOrientation] = useState('unknown');
  const [location, setLocation] = useState(null);
  const [optimalTilt, setOptimalTilt] = useState(null);
  const [minTilt, setMinTilt] = useState(null);
  const [maxTilt, setMaxTilt] = useState(null);
  const [season, setSeason] = useState('');
  const [region, setRegion] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Determine Season Based on Current Month and Hemisphere
  useEffect(() => {
    if (!location) return;

    const date = new Date();
    const month = date.getMonth(); // 0-11 where 0 is January
    
    // Determine hemisphere based on latitude
    const isNorthernHemisphere = location.latitude > 0;
    
    let calculatedSeason;
    if (isNorthernHemisphere) {
      // Northern Hemisphere seasons
      if (month >= 2 && month <= 4) calculatedSeason = 'spring';
      else if (month >= 5 && month <= 7) calculatedSeason = 'summer';
      else if (month >= 8 && month <= 10) calculatedSeason = 'autumn';
      else calculatedSeason = 'winter';
    } else {
      // Southern Hemisphere seasons
      if (month >= 11 || month <= 1) calculatedSeason = 'summer';
      else if (month >= 2 && month <= 4) calculatedSeason = 'autumn';
      else if (month >= 5 && month <= 7) calculatedSeason = 'winter';
      else calculatedSeason = 'spring';
    }

    setSeason(calculatedSeason);
  }, [location]);

  // Obtain Geolocation
  useEffect(() => {
    setIsLoading(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          });
          setIsLoading(false);
        },
        (err) => {
          console.error('Location error:', err);
          setError('Unable to retrieve location. Please enable location services.');
          setIsLoading(false);
        }
      );
    } else {
      setError('Geolocation is not supported by your browser.');
      setIsLoading(false);
    }
  }, []);

  // Calculate Optimal Tilt Based on Location and Season
  useEffect(() => {
    if (!location || !season) return;

    const latitude = Math.abs(location.latitude);
    const isNorthernHemisphere = location.latitude > 0;
    let currentRegion = 'Other';
    let tilt;
    
    // Determine Region Based on Latitude
    if (latitude >= 25 && latitude <= 27 && !isNorthernHemisphere) {
      currentRegion = 'Gauteng';
    } else if (latitude >= 32 && latitude <= 35 && !isNorthernHemisphere) {
      currentRegion = 'Western Cape';
    }

    setRegion(currentRegion);

    // Calculate Base Tilt Based on Season and Hemisphere
    switch (season) {
      case 'summer':
        tilt = latitude * 0.9 - 23.5;
        break;
      case 'winter':
        tilt = latitude * 0.9 + 29;
        break;
      case 'spring':
      case 'autumn':
        tilt = latitude - 2.5;
        break;
      default:
        tilt = latitude;
    }

    // Set Min and Max Tilt Based on Region and Latitude
    let minTiltValue, maxTiltValue;
    if (currentRegion === 'Gauteng') {
      minTiltValue = 25;
      maxTiltValue = 30;
    } else if (currentRegion === 'Western Cape') {
      minTiltValue = 30;
      maxTiltValue = 35;
    } else {
      minTiltValue = Math.max(15, tilt - 10);
      maxTiltValue = Math.min(45, tilt + 10);
    }

    // Ensure optimal tilt is within min/max range
    tilt = Math.max(minTiltValue, Math.min(maxTiltValue, tilt));
    
    setMinTilt(Math.round(minTiltValue));
    setMaxTilt(Math.round(maxTiltValue));
    setOptimalTilt(Math.round(tilt));
  }, [location, season]);

  // Handle Device Orientation Events
  useEffect(() => {
    if (window.DeviceOrientationEvent) {
      startMeasuring();
    } else {
      setError('Device orientation not supported.');
    }

    const checkOrientation = () => {
      if (window.screen.orientation) {
        setDeviceOrientation(window.screen.orientation.type);
      } else if (typeof window.orientation === 'number') {
        const angle = window.orientation;
        if (angle === 0) setDeviceOrientation('portrait-primary');
        else if (angle === 90) setDeviceOrientation('landscape-primary');
        else if (angle === -90) setDeviceOrientation('landscape-secondary');
        else if (angle === 180) setDeviceOrientation('portrait-secondary');
      }
    };

    window.addEventListener('orientationchange', checkOrientation);
    checkOrientation();

    return () => {
      window.removeEventListener('orientationchange', checkOrientation);
      window.removeEventListener('deviceorientation', handleOrientation);
    };
  }, []);

  // Calculate Tilt Based on Orientation and Calibration
  const calculateTilt = (beta, gamma, deviceOrient, calibrationValues) => {
    if (beta < 0) {
      beta = Math.abs(beta);
      gamma = gamma > 0 ? 180 - gamma : -180 - gamma;
    }

    let tilt;
    if (deviceOrient.includes('landscape')) {
      tilt = Math.abs(gamma);
      if (calibrationValues.gamma !== null) {
        tilt = tilt - calibrationValues.gamma;
      }
    } else {
      tilt = beta;
      if (calibrationValues.beta !== null) {
        tilt = tilt - calibrationValues.beta;
      }
    }

    return Math.max(0, Math.min(90, tilt));
  };

  // Handle Orientation Event
  const handleOrientation = (event) => {
    const beta = event.beta || 0;
    const gamma = event.gamma || 0;
    const alpha = event.alpha || 0;

    const tilt = calculateTilt(beta, gamma, deviceOrientation, calibration);

    setOrientation({
      alpha: Math.round(alpha),
      beta: Math.round(beta),
      gamma: Math.round(gamma),
      tilt: Math.round(tilt)
    });
  };

  // Start Measuring Device Orientation
  const startMeasuring = async () => {
    try {
      if (typeof DeviceOrientationEvent.requestPermission === 'function') {
        const permission = await DeviceOrientationEvent.requestPermission();
        if (permission === 'granted') {
          window.addEventListener('deviceorientation', handleOrientation);
        } else {
          setError('Permission denied for accessing device orientation.');
        }
      } else {
        window.addEventListener('deviceorientation', handleOrientation);
      }
    } catch (err) {
      setError('Error accessing sensors: ' + err.message);
    }
  };

  // Calibration Functions
  const calibrate = () => {
    setIsCalibrating(true);
    setCalibrationProgress(0);
    
    const duration = 3000; // 3 seconds calibration
    const interval = 50; // Update every 50ms
    const steps = duration / interval;
    let currentStep = 0;

    const progressInterval = setInterval(() => {
      currentStep++;
      setCalibrationProgress((currentStep / steps) * 100);
      
      if (currentStep >= steps) {
        clearInterval(progressInterval);
        setCalibration({
          beta: deviceOrientation.includes('portrait') ? orientation.beta : null,
          gamma: deviceOrientation.includes('landscape') ? orientation.gamma : null
        });
        setIsCalibrating(false);
        setCalibrationProgress(0);
      }
    }, interval);
  };

  const resetCalibration = () => {
    setCalibration({ beta: null, gamma: null });
  };

  // Get Tilt Status with Enhanced Feedback
  const getTiltStatus = () => {
    if (isLoading) return {
      message: "Loading...",
      color: "text-gray-500",
      icon: null
    };
    
    if (optimalTilt === null) return { 
      message: "Calculating optimal angle...", 
      color: "text-gray-500",
      icon: null
    };
    
    const diff = orientation.tilt - optimalTilt;
    const absDiff = Math.abs(diff);

    if (absDiff <= 2) return { 
      message: "Perfect!", 
      color: isDarkMode ? "text-green-400" : "text-green-500",
      icon: null
    };
    if (absDiff <= 5) return { 
      message: "Almost there", 
      color: isDarkMode ? "text-yellow-400" : "text-yellow-500",
      icon: null
    };
    
    return { 
      message: diff > 0 ? "Tilt down" : "Tilt up",
      icon: diff > 0 ? 
        <ChevronDown size={14} /> : 
        <ChevronUp size={14} />,
      color: isDarkMode ? "text-red-400" : "text-red-500" 
    };
  };

  // Render Component
  return (
    <div className="min-h-screen w-full flex items-center justify-center p-2 sm:p-4">
      <div className={`w-full max-w-md rounded-lg shadow-lg overflow-hidden 
        ${isDarkMode ? 'bg-blue-800' : 'bg-amber-50'}`}>
        
        {/* Header */}
        <div className={`p-4 sm:p-6 border-b ${isDarkMode ? 'border-blue-700' : 'border-amber-200'}`}>
          <h2 className={`text-lg sm:text-xl font-bold text-center 
            ${isDarkMode ? 'text-blue-100' : 'text-amber-900'}`}>
            Solar Panel Tilt Gauge
          </h2>
        </div>
        
        {/* Main Content */}
        <div className="p-4 sm:p-6 space-y-4 sm:space-y-6">
          {error ? (
            <div className={`text-center text-red-500 p-4 
              ${isDarkMode ? 'bg-blue-700' : 'bg-amber-100'} rounded-lg`}>
              <AlertTriangle className="h-6 w-6 sm:h-8 sm:w-8 mx-auto mb-2" />
              {error}
            </div>
          ) : (
            <div className="space-y-4 sm:space-y-6">
              
              {/* Instructions */}
              <div className={`p-3 sm:p-4 rounded-lg text-center
                ${isDarkMode ? 'bg-blue-700' : 'bg-amber-100'}`}>
                <p className={`text-xs sm:text-sm ${isDarkMode ? 'text-blue-100' : 'text-amber-800'}`}>
                  {isCalibrating 
                    ? "Hold still while calibrating..." 
                    : deviceOrientation.includes('landscape')
                      ? "Place device against panel in landscape mode"
                      : "Place device against panel in portrait mode"}
                </p>
              </div>

              {/* Calibration Controls */}
              <div className="flex justify-center items-center gap-3">
                <button
                  onClick={calibrate}
                  disabled={isCalibrating}
                  className={`flex items-center gap-2 px-4 sm:px-6 py-2 sm:py-3 rounded-lg text-sm sm:text-base font-medium
                    ${isDarkMode 
                      ? 'bg-blue-600 hover:bg-blue-500 disabled:bg-blue-700' 
                      : 'bg-amber-500 hover:bg-amber-400 disabled:bg-amber-300'} 
                    text-white transition-colors disabled:cursor-not-allowed`}
                >
                  <RotateCcw className={`h-3 w-3 sm:h-4 sm:w-4 ${isCalibrating ? 'animate-spin' : ''}`} />
                  {isCalibrating ? 'Calibrating...' : 'Calibrate Zero'}
                </button>
                
                {(calibration.beta !== null || calibration.gamma !== null) && (
                  <button
                    onClick={resetCalibration}
                    className={`flex items-center gap-1 text-xs sm:text-sm hover:underline
                      ${isDarkMode ? 'text-blue-300' : 'text-amber-600'}`}
                  >
                    <RefreshCw className="h-3 w-3" />
                    Reset
                  </button>
                )}
              </div>

              {/* Calibration Progress */}
              {isCalibrating && (
                <div className="relative w-24 h-24 sm:w-32 sm:h-32 mx-auto">
                  <div className={`absolute inset-0 rounded-full 
                    ${isDarkMode ? 'bg-blue-700' : 'bg-amber-200'}`} />
                  <div
                    className={`absolute inset-0 rounded-full transition-all duration-50
                      ${isDarkMode ? 'bg-blue-500' : 'bg-amber-400'}`}
                    style={{
                      clipPath: `polygon(0 0, 100% 0, 100% ${calibrationProgress}%, 0 ${calibrationProgress}%)`
                    }}
                  />
                  <div className="absolute inset-0 flex items-center justify-center">
                    <p className={`text-base sm:text-lg font-bold
                      ${isDarkMode ? 'text-blue-100' : 'text-amber-900'}`}>
                      {Math.round(calibrationProgress)}%
                    </p>
                  </div>
                </div>
              )}

              {/* Gauge Display */}
              <div className="relative w-56 h-56 sm:w-64 sm:h-64 mx-auto">
                {/* Base Circle */}
                <div className={`absolute w-full h-full rounded-full border-6 sm:border-8 
                  ${isDarkMode ? 'border-blue-600/30' : 'border-amber-200'}`} />

                {/* Active Arc */}
                <div className="absolute w-full h-full">
                  <svg className="w-full h-full -rotate-90">
                    <circle
                      className={`${(() => {
                        const diff = Math.abs(orientation.tilt - optimalTilt);
                        if (diff <= 2) return isDarkMode ? 'stroke-green-500' : 'stroke-green-500';
                        if (diff <= 5) return isDarkMode ? 'stroke-yellow-500' : 'stroke-yellow-500';
                        return isDarkMode ? 'stroke-red-500' : 'stroke-red-500';
                      })()} transition-colors duration-300`}
                      cx="50%"
                      cy="50%"
                      r="46%"
                      fill="none"
                      strokeWidth="12"
                      strokeLinecap="round"
                      strokeDasharray={`${Math.PI * 92 * 0.5} ${Math.PI * 92}`}
                    />
                  </svg>
                </div>

                {/* Degree Markers */}
                {[...Array(19)].map((_, i) => {
                  const degree = i * 5;
                  return (
                    <div
                      key={i}
                      className="absolute w-full h-full"
                      style={{ transform: `rotate(${degree * 2}deg)` }}
                    >
                      <div className={`absolute top-0 left-1/2 -ml-px h-2 sm:h-3 w-0.5
                        ${isDarkMode ? 'bg-blue-400/50' : 'bg-amber-400/50'}`} />
                      {i % 2 === 0 && (
                        <div className={`absolute top-4 sm:top-6 left-1/2 transform -translate-x-1/2 text-[8px] sm:text-[10px] font-medium
                          ${isDarkMode ? 'text-blue-200' : 'text-amber-700'}`}>
                          {degree}°
                        </div>
                      )}
                    </div>
                  );
                })}

                {/* Current Tilt Indicator */}
                <div 
                  className="absolute w-full h-full transition-transform duration-200"
                  style={{ transform: `rotate(${orientation.tilt * 2}deg)` }}
                >
                  <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1">
                    <ArrowUp className={`h-4 w-4 sm:h-6 sm:w-6 ${isDarkMode ? 'text-blue-300' : 'text-amber-500'}`} />
                  </div>
                </div>

                {/* Center Display */}
                <div className="absolute inset-0 flex flex-col items-center justify-center">
                  <div className={`text-2xl sm:text-4xl font-bold ${isDarkMode ? 'text-blue-100' : 'text-amber-900'}`}>
                    {orientation.tilt || 0}°
                  </div>
                  {optimalTilt && (
                    <div className={`text-xs sm:text-sm mt-1 ${getTiltStatus().color} flex items-center gap-1`}>
                      {getTiltStatus().icon}
                      {getTiltStatus().message}
                    </div>
                  )}
                  <div className={`text-sm sm:text-base mt-1 ${isDarkMode ? 'text-blue-300' : 'text-amber-600'}`}>
                    {deviceOrientation.includes('portrait') ? 'Portrait' : 'Landscape'}
                  </div>
                </div>
              </div>

              {/* Location Info */}
              <div className={`p-3 sm:p-4 rounded-lg ${isDarkMode ? 'bg-blue-700/50' : 'bg-amber-100/50'}`}>
                <div className="flex items-center justify-center gap-2 mb-1 sm:mb-2">
                  <MapPin className={isDarkMode ? "text-blue-300" : "text-amber-600"} size={14} />
                  <span className={`text-sm sm:text-base font-medium ${isDarkMode ? 'text-blue-200' : 'text-amber-800'}`}>
                    {region || 'Detecting region...'}
                  </span>
                </div>
                <div className={`text-xs sm:text-sm ${isDarkMode ? 'text-blue-200' : 'text-amber-700'}`}>
                  <p>Season: {season.charAt(0).toUpperCase() + season.slice(1)}</p>
                  <p>Optimal Tilt: {optimalTilt}°</p>
                  <p>Acceptable Range: {minTilt}° - {maxTilt}°</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SolarTiltGauge; 