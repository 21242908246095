import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTheme } from '../contexts/ThemeContext';

const SearchResults = () => {
  const { isDarkMode } = useTheme();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const query = searchParams.get('q');

  // This is a placeholder. You'll need to implement actual search functionality
  const searchResults = [
    {
      title: "Solar Panels",
      description: "High-efficiency solar panels for your home",
      link: "/product/panels"
    },
    {
      title: "Battery Storage",
      description: "Energy storage solutions for continuous power",
      link: "/product/batteries"
    },
    // Add more mock results as needed
  ];

  return (
    <div className="max-w-4xl mx-auto py-8">
      <h1 className={`text-2xl font-bold mb-6 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
        Search Results for "{query}"
      </h1>
      
      {searchResults.length > 0 ? (
        <div className="space-y-4">
          {searchResults.map((result, index) => (
            <div 
              key={index}
              className={`p-4 rounded-lg ${
                isDarkMode ? 'bg-blue-800 hover:bg-blue-700' : 'bg-amber-800 hover:bg-amber-700'
              } transition-colors duration-200`}
            >
              <a href={result.link} className="block">
                <h2 className="text-xl font-semibold text-yellow-400 mb-2">
                  {result.title}
                </h2>
                <p className="text-gray-200">
                  {result.description}
                </p>
              </a>
            </div>
          ))}
        </div>
      ) : (
        <p className={`text-lg ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
          No results found for "{query}"
        </p>
      )}
    </div>
  );
};

export default SearchResults; 