import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { useTheme } from '../contexts/ThemeContext';
import { Helmet } from 'react-helmet';
import { Sun, Battery, Zap, Anchor, Cpu } from 'lucide-react';
import SolarPanelPage from './products/SolarPanelPage';
import BatteryPage from './products/BatteryPage';
import InverterPage from './products/InverterPage';
import MountingSystemPage from './products/MountingSystemPage';
import ChargeControllerPage from './products/ChargeControllerPage';

const ProductPage = () => {
  const { isDarkMode } = useTheme();
  const { category } = useParams();

  const products = {
    all: {
      title: 'Solar Energy Products',
      icon: Sun,
      description: 'Explore our range of solar energy products for your home or business.',
      categories: [
        { id: 'panels', name: 'Solar Panels', icon: Sun, description: 'Convert sunlight into electricity' },
        { id: 'batteries', name: 'Batteries', icon: Battery, description: 'Store energy for use during non-sunny periods' },
        { id: 'inverters', name: 'Inverters', icon: Zap, description: 'Convert DC to AC for home use' },
        { id: 'mounting', name: 'Mounting Systems', icon: Anchor, description: 'Secure solar panels to roofs or ground' },
        { id: 'controllers', name: 'Charge Controllers', icon: Cpu, description: 'Regulate voltage and current from solar panels to batteries' },
      ],
    },
  };

  const renderProductPage = () => {
    switch(category) {
      case 'panels':
        return <SolarPanelPage />;
      case 'batteries':
        return <BatteryPage />;
      case 'inverters':
        return <InverterPage />;
      case 'mounting':
        return <MountingSystemPage />;
      case 'controllers':
        return <ChargeControllerPage />;
      default:
        return renderAllProducts();
    }
  };

  const renderAllProducts = () => (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      {products.all.categories.map((cat) => (
        <Link 
          key={cat.id} 
          to={`/product/${cat.id}`}
          className={`p-6 rounded-lg ${isDarkMode ? 'bg-blue-800 hover:bg-blue-700' : 'bg-white hover:bg-amber-100'} shadow-md transition-all duration-300 ease-in-out`}
        >
          <div className="flex items-center justify-between mb-4">
            <cat.icon className={`w-10 h-10 ${isDarkMode ? 'text-yellow-400' : 'text-amber-500'}`} />
          </div>
          <h3 className="text-xl font-semibold mb-2">{cat.name}</h3>
          <p>{cat.description}</p>
        </Link>
      ))}
    </div>
  );

  const getPageTitle = (category) => {
    switch(category) {
      case 'panels':
        return 'Solar Panels';
      case 'batteries':
        return 'Solar Batteries & Energy Storage';
      case 'inverters':
        return 'Solar Inverters & Power Conversion';
      case 'mounting':
        return 'Solar Panel Mounting Systems';
      case 'controllers':
        return 'Solar Charge Controllers';
      default:
        return 'Solar Energy Products';
    }
  };

  const getMetaDescription = (category) => {
    switch(category) {
      case 'panels':
        return 'High-quality solar panels for residential and commercial use. Find the perfect solar panel solution for your energy needs.';
      case 'batteries':
        return 'Explore our range of high-capacity solar batteries and energy storage solutions for reliable power backup.';
      case 'inverters':
        return 'Find the perfect solar inverter for your solar power system. Compare different types and features.';
      case 'mounting':
        return 'Professional mounting solutions for solar panels. Find the perfect mounting system for your roof type.';
      case 'controllers':
        return 'Advanced solar charge controllers for optimal battery charging and system protection.';
      default:
        return 'Explore our complete range of solar energy products and solutions for your home or business.';
    }
  };

  const getKeywords = (category) => {
    const baseKeywords = 'solar energy, South Africa';
    switch(category) {
      case 'panels':
        return `solar panels, photovoltaic panels, PV modules, ${baseKeywords}`;
      case 'batteries':
        return `solar batteries, energy storage, battery backup, ${baseKeywords}`;
      case 'inverters':
        return `solar inverters, power conversion, grid-tie inverters, ${baseKeywords}`;
      case 'mounting':
        return `solar mounting, panel installation, roof mounts, ${baseKeywords}`;
      case 'controllers':
        return `charge controllers, MPPT controllers, PWM controllers, ${baseKeywords}`;
      default:
        return `solar products, renewable energy, solar equipment, ${baseKeywords}`;
    }
  };

  const getJsonLd = (category) => {
    return {
      "@context": "https://schema.org",
      "@type": "Product",
      "category": getPageTitle(category),
      "name": `${getPageTitle(category)} | Solar Insight`,
      "description": getMetaDescription(category),
      "brand": {
        "@type": "Brand",
        "name": "Solar Insight"
      }
    };
  };

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-blue-900 text-blue-100' : 'bg-amber-50 text-amber-900'}`}>
      <Helmet>
        <title>{getPageTitle(category)} | Solar Insight</title>
        <meta name="description" content={getMetaDescription(category)} />
        <meta name="keywords" content={getKeywords(category)} />
        
        <meta property="og:title" content={getPageTitle(category)} />
        <meta property="og:description" content={getMetaDescription(category)} />
        <meta property="og:type" content="product" />
        <meta property="og:url" content={`https://solarinsight.co.za/products/${category}`} />
        <meta property="og:image" content={`https://solarinsight.co.za/images/products/${category}.jpg`} />
        
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={getPageTitle(category)} />
        <meta name="twitter:description" content={getMetaDescription(category)} />
        <meta name="twitter:image" content={`https://solarinsight.co.za/images/products/${category}-twitter.jpg`} />
        
        <link rel="canonical" href={`https://solarinsight.co.za/products/${category}`} />
        
        <script type="application/ld+json">
          {JSON.stringify(getJsonLd(category))}
        </script>
      </Helmet>

      <main className="container mx-auto px-4 py-8">
        <h1 className={`text-4xl font-bold mb-8 ${isDarkMode ? 'text-yellow-400' : 'text-amber-600'}`}>
          {category === 'all' ? 'Solar Energy Products' : products.all.categories.find(cat => cat.id === category)?.name}
        </h1>

        {renderProductPage()}
      </main>
    </div>
  );
};

export default ProductPage;